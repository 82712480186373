define("front/controllers/person/simple-merge", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      persons: null,
      searchString: null,
      isAdmin: false,
      selectedPerson: null,

      actions: {
        search: function () {
          var self = this;

          this.set("showSpinner", true);
          this.store.find("person", { searchString: this.get("searchString") }).then(function (persons) {
            self.set("persons", persons);
            self.set("searchString", null);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("persons", null);
            self.set("searchString", null);
            self.set("showSpinner", false);
          });
        },

        selectPerson: function (id) {
          var self = this;
          this.store.find("person", id).then(function (response) {
            self.set("selectedPerson", response);
          });
        },

        warning: function () {
          var self = this;
          if (!this.get("selectedPerson")) {
            Notify.warning("Δεν έχετε επιλέξει άτομο", {
              closeAfter: 10000
            });
          } else {
            this.send("showModal", "person/merge-warning", { person_from: self.get("selectedPerson"), person_to: self.get("model") });
          }
        }
      }
    });
  });