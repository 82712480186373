define("front/templates/person/ascertainment", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n	<div class=\"row\">\n		<div class=\"col-xs-12 col-md-12\">\n			");
      stack1 = helpers['if'].call(depth0, "has_history", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(6, program6, data),fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n	</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n				<div class=\"widget-box\">\n					<div class=\"widget-header\">\n						<h4 class=\"widget-title smaller\">Βεβαίωση παρακολούθησης - ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n					</div>\n					<div class=\"widget-body\">\n						<div class=\"widget-main\">\n\n							<div class=\"row\">\n								<div class=\"col-xs-12 col-sm-10 col-sm-offset-1\">\n									\n									");
      stack1 = helpers.each.call(depth0, "groupedResults", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								</div>\n							</div>\n\n						\n							\n						</div>\n					</div>\n				</div>\n			");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"timeline-container timeline-style2\">\n\n										<span class=\"timeline-label\">\n										<b>");
      stack1 = helpers._triageMustache.call(depth0, "year", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b>\n										</span>\n										<div class=\"timeline-items\">\n										");
      stack1 = helpers.each.call(depth0, "contents", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										</div>\n									</div>\n									");
      return buffer;
      }
    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n										\n											<div class=\"timeline-item clearfix\">\n												<div class=\"timeline-info\">\n													<span class=\"timeline-date\">");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "startDate", options) : helperMissing.call(depth0, "format-date", "startDate", options))));
      data.buffer.push("</span>\n\n													<i class=\"timeline-indicator btn btn-info no-hover\"></i>\n													<span class=\"pdf-icon fa fa-file-pdf-o fa-3x blue\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "download", "id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push("></span>\n												</div>\n\n												<div class=\"widget-box transparent\">\n													<div class=\"widget-body\">\n														<div class=\"widget-main no-padding\">\n															<span class=\"bigger-110\">\n																<div class=\"profile-user-info profile-user-info-striped\">\n																	<div class=\"profile-info-row\">\n							\n																			<div class=\"profile-info-name\">Ιδιότητα</div>\n																			<div class=\"profile-info-value\">\n																				");
      stack1 = helpers._triageMustache.call(depth0, "status", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n																			</div>\n																	</div>\n																	\n								    							<div class=\"profile-info-row\">\n												\n																		<div class=\"profile-info-name\">Δομή</div>\n																		<div class=\"profile-info-value\">\n																			");
      stack1 = helpers._triageMustache.call(depth0, "program_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n																		</div>\n																	</div>\n\n																	<div class=\"profile-info-row\">\n												\n																		<div class=\"profile-info-name\">Δομή</div>\n																		<div class=\"profile-info-value\">\n																			");
      stack1 = helpers._triageMustache.call(depth0, "structure_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n																		</div>\n																	</div>\n\n																	<div class=\"profile-info-row\">\n												\n																		<div class=\"profile-info-name\">Μονάδα</div>\n																		<div class=\"profile-info-value\">\n																			");
      stack1 = helpers._triageMustache.call(depth0, "unit_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n																		</div>\n																	</div>\n																</div>\n															\n															</span>\n														</div>\n													</div>\n												</div>\n											</div>\n										\n										");
      return buffer;
      }

    function program6(depth0,data) {
      
      
      data.buffer.push("\n				<div class=\"space-10\"></div>\n				<div class=\"alert alert-danger\">\n					Το άτομο δεν έχει υπάρξει ενεργό άτομο σε κάποια μονάδα\n				</div>\n			");
      }

    function program8(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n  <div class=\"page-header\">\n    <h1>");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Δήλωση Ολοκλήρωσης</small></h1>\n    \n  </div>\n\n	<div class=\"space-10\"></div>\n	<div class=\"alert alert-danger\">\n		Ο χρήστης δεν έχει δικαίωμα να πραγματοποιήσει την ενέργεια αυτή\n	</div>\n");
      return buffer;
      }

      data.buffer.push("\n");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(8, program8, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      return buffer;
      
    });
  });