define("front/templates/reports/new-persons", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n              <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.dateFrom.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n              ");
      return buffer;
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n              <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.dateTo.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n              ");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Νέοι Ωφελούμενοι / Δομή</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n\n        <form class=\"form-horizontal\" role=\"form\">\n\n          <div class=\"form-group\">\n            <label for=\"date_from\" class=\"col-sm-3 control-label\">Ημ/νία από:</label>\n            <div class=\"col-sm-4\">\n              <div class=\"input-group date\" id=\"date_from\">\n\n                ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateFrom")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n                <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n              </div>\n            </div>\n            <div class=\"col-sm-12\"></div>\n            <div class=\"col-sm-4 col-sm-offset-3\">\n              ");
      stack1 = helpers['if'].call(depth0, "errors.dateFrom.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n          </div>\n\n          <div class=\"form-group\">\n            <label for=\"date_to\" class=\"col-sm-3 control-label\">Ημ/νία έως:</label>\n            <div class=\"col-sm-4\">\n              <div class=\"input-group date\" id=\"date_to\">\n\n                ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateTo")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n                <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n              </div>\n            </div>\n            <div class=\"col-sm-12\"></div>\n            <div class=\"col-sm-4 col-sm-offset-3\">\n              ");
      stack1 = helpers['if'].call(depth0, "errors.dateTo.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n          </div>\n\n          <div class=\"form-group\">\n            <div class=\"col-sm-offset-3 col-sm-6\">\n\n              <button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n                Εκτύπωση\n              </button>\n\n            </div>\n          </div>\n\n        </form>\n\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });