define("front/routes/person/form-report", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("forminfo");
      },

      setupController: function (controller, model) {
        controller.set("forms", model);
        controller.set("person", this.controllerFor("person").get("person"));
        controller.set("selectedForms", model.map(function (m) {
          return m;
        }));
      } });
  });