define("front/routes/person", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return Ember.RSVP.hash({
          person: this.store.find("person", params.person_id),
          transfer: this.store.find("transfer", { pers_id: params.person_id, pending: "1", canceled: "0" }),
          relationships: this.store.find("relationship", { pers_id: params.person_id }),
          person_histories: this.store.find("person_history", {
            pers_id: params.person_id
          })
        });
      },
      setupController: function (controller, model) {
        this._super(controller, model);

        var ucu = this.controllerFor("application").get("selectedUnit");
        var self = this;
        model.person.reload();
        controller.set("person", model.person);
        controller.set("personID", model.person.get("id"));
        controller.set("personCrntUn", model.person.get("currentUnit.id"));
        controller.set("person_histories", model.person_histories);
        controller.set("user_current_unit", ucu);
        controller.set("transfers", model.transfer);
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));
        //unit_id: ucu.get('id')
      },
      deactivate: function () {
        this.set("controller.related", []);
      },
      actions: {
        reloadPerson: function () {
          var self = this;
          this.modelFor("person").person.reload().then(function (person) {
            self.refresh();
            //self.controllerFor('person').set('personCrntUn', person.get('currentUnit.id'));
          });
        },
        goBack: function goBack() {
          window.history.back(); // Goes back to the previous page in the browser history
        }
      }
    });
  });