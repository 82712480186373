define("front/controllers/login", 
  ["simple-auth/mixins/login-controller-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var LoginControllerMixin = __dependency1__["default"];

    var LoginController = Ember.Controller.extend(LoginControllerMixin, {
      needs: ["application"],
      authenticator: "simple-auth-authenticator:oauth2-password-grant",
      klogoUrl: (function () {
        return "/images/login.jpg";
      }).property(),

      loginBanner: (function () {
        return "/images/logo-new.png";
      }).property(),

      sosBanner: (function () {
        return "/images/sos.jpg";
      }).property(),

      greekFlag: (function () {
        return "/images/greek-flag.jpg";
      }).property(),

      aemyLogo: (function () {
        return "/images/aemy-logo.png";
      }).property(),

      footerBanner: (function () {
        return "/images/footer.jpg";
      }).property() });
    __exports__["default"] = LoginController;
  });