define("front/templates/dashboard", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								");
      stack1 = helpers.each.call(depth0, "groups", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"panel panel-info\">\n									<div class=\" accordion-heading panel-heading \">\n										<h4 class=\"panel-title bigger\">\n											<a data-toggle=\"collapse\" data-parent=\"#accordion\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'href': ("hashGroupId")
      },hashTypes:{'href': "ID"},hashContexts:{'href': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n												");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n											</a>\n											");
      stack1 = helpers['if'].call(depth0, "isUnitGroup", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n											");
      stack1 = helpers.unless.call(depth0, "isSystemGroup", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										</h4>\n									</div>\n									<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("groupId")
      },hashTypes:{'id': "ID"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"panel-collapse collapse\">\n										<div class=\"panel-body\">\n											<table class=\"table table-condensed table-hover\">\n												<tbody>\n													");
      stack1 = helpers.each.call(depth0, "persons", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n												</tbody>\n											</table>\n\n										</div>\n									</div>\n								</div>\n								");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n												<a href=\"#\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printUnitGroupInfo", "", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data})));
      data.buffer.push(" class=\"pull-right\">Εκτύπωση ατόμων</a>\n											");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n													<tr>\n														<td>\n															<span class=\"bolder blue\">");
      data.buffer.push(escapeExpression((helper = helpers['add-index'] || (depth0 && depth0['add-index']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "_view.contentIndex", options) : helperMissing.call(depth0, "add-index", "_view.contentIndex", options))));
      data.buffer.push(" - </span>\n															");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person.index", "id", options) : helperMissing.call(depth0, "link-to", "person.index", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n														</td>\n													</tr>\n													");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("<span class=\"bolder blue\">");
      stack1 = helpers._triageMustache.call(depth0, "lastName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "firstName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" </span>\n															");
      return buffer;
      }

      data.buffer.push("<div class=\"tabbable\">\n\n	<ul id=\"myTab\" class=\"nav nav-tabs tab-size-bigger padding-32 tab-space-4\">\n		<li class=\"active\">\n			<a href=\"#search_tab\" data-toggle=\"tab\">Αναζήτηση</a>\n		</li>\n		<li>\n			<a href=\"#advanced_search_tab\" data-toggle=\"tab\">Σύνθετη Αναζήτηση</a>\n		</li>\n		<li>\n\n			<a href=\"#person_group_tab\" data-toggle=\"tab\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "personGroups", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ομάδες</a>\n		</li>\n	</ul>\n\n	<div class=\"tab-content\">\n		<div class=\"tab-pane in active\" id=\"search_tab\">\n			<div class=\"space-8\"></div>\n			<div class=\"row\">\n				<div class=\"col-xs-10 col-xs-offset-1\">\n					<div class=\"input-group\">\n						");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("text"),
        'class': ("form-control"),
        'value': ("searchString"),
        'action': ("search"),
        'placeholder': ("Αναζήτηση")
      },hashTypes:{'type': "STRING",'class': "STRING",'value': "ID",'action': "STRING",'placeholder': "STRING"},hashContexts:{'type': depth0,'class': depth0,'value': depth0,'action': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n						<span class=\"input-group-btn\">\n							<button class=\"btn btn-info btn-sm\" type=\"button\" title=\"Αναζήτηση\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "search", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n								<span class=\"glyphicon glyphicon-search\"></span>\n							</button>\n						</span>\n						<span class=\"input-group-btn\">\n							<button class=\"btn btn-warning btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "clearSearch", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Καθαρισμος αναζήτησης</button>\n						</span>\n					</div><!-- /input-group -->\n				</div>\n				<div class=\"col-xs-1\">\n					<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n				</div>\n			</div>\n			<br>\n			<div class=\"row\">\n				<div class=\"col-xs-12\">\n					<div class=\"table-header\">Αποτελέσματα Αναζήτησης </div>\n				</div>\n			</div>\n			<div class=\"row\">\n				<div class=\"col-xs-12\">\n\n					");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "persons-search-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n				</div>\n			</div>\n		</div>\n		<div class=\"tab-pane\" id=\"advanced_search_tab\">\n			<div class=\"space-12\"></div>\n			<div class=\"row\">\n				<form class=\"form-horizontal\" role=\"form\">\n					<div class=\"col-sm-6\">\n						<div class=\"form-group\">\n							<label for=\"code\" class=\"col-sm-3 control-label blue bolder\">Κωδικός Φυσικού Φακέλου</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("physfile"),
        'id': ("physfile"),
        'placeholder': ("Κωδικός Φυσικού Φακέλου")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"afm\" class=\"col-sm-3 control-label blue bolder\">ΑΦΜ</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("afm"),
        'id': ("αφμ"),
        'placeholder': ("ΑΦΜ")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"lastName\" class=\"col-sm-3 control-label blue bolder\">Επώνυμο</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("lastName"),
        'id': ("lastName"),
        'placeholder': ("Επώνυμο")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"firstName\" class=\"col-sm-3 control-label blue bolder\">Όνομα</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("firstName"),
        'id': ("firstName"),
        'placeholder': ("Όνομα")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"selectedProgram\" class=\"col-sm-3 control-label blue bolder\">Δομή</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'class': ("form-control navbar-form-control"),
        'id': ("programs"),
        'prompt': ("Επιλέξτε δομή"),
        'content': ("model.programs"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'selectionBinding': ("selectedProgram")
      },hashTypes:{'class': "STRING",'id': "STRING",'prompt': "STRING",'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'selectionBinding': "STRING"},hashContexts:{'class': depth0,'id': depth0,'prompt': depth0,'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'selectionBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"col-sm-12\">\n							<div class=\"row\">\n								\n								<div class=\"col-sm-6\">\n\n									<div class=\"form-group\">\n										<label for=\"isNursing\" class=\"col-sm-3 control-label blue bolder\">Περιθαλπτόμενος</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isNursing"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n\n									<div class=\"form-group\">\n										<label for=\"isChild\" class=\"col-sm-3 control-label blue bolder\">Παιδί</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isChild"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isEpitokos\" class=\"col-sm-3 control-label blue bolder\">Επίτοκος</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isEpitokos"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isAnadoxosParent\" class=\"col-sm-3 control-label blue bolder\">Ανάδοχος Γονέας</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isAnadoxosParent"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isThetosParent\" class=\"col-sm-3 control-label blue bolder\">Θετός Γονέας</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isThetosParent"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n								</div>\n								<div class=\"col-sm-6\">\n									<div class=\"form-group\">\n										<label for=\"isEthelontis\" class=\"col-sm-3 control-label blue bolder\">Εθελοντής</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isEthelontis"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isFamilyMember\" class=\"col-sm-3 control-label blue bolder\">Μέλος Οικογένειας</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isFamilyMember"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isAllo\" class=\"col-sm-3 control-label blue bolder\">Άλλο</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isAllo"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n\n									<div class=\"form-group\">\n										<label for=\"isOfeloumenos\" class=\"col-sm-3 control-label blue bolder\">Ωφελούμενος</label>\n										<div class=\"col-sm-2\">\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("isOfeloumenos"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n										</div>\n									</div>\n								</div>\n							</div>		\n						</div>\n					</div>\n					<div class=\"col-sm-6\">\n						<div class=\"form-group\">\n							<label for=\"amka\" class=\"col-sm-3 control-label blue bolder\">ΑΜΚΑ</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("amka"),
        'id': ("amka"),
        'placeholder': ("ΑΜΚΑ")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"fatherName\" class=\"col-sm-3 control-label blue bolder\">Πατρώνυμο</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("fatherName"),
        'id': ("fatherName"),
        'placeholder': ("Πατρώνυμο")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"motherName\" class=\"col-sm-3 control-label blue bolder\">Μητρώνυμο</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("motherName"),
        'id': ("motherName"),
        'placeholder': ("Μητρώνυμο")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'placeholder': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"birthDate\" class=\"col-sm-3 control-label blue bolder\">Ημ.Γεν.</label>\n							<div class=\"col-sm-9\">\n								<div class=\"input-group date\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("birthDate"),
        'id': ("birthDate")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n									<span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n								</div>\n							</div>\n						</div>\n\n						\n\n						<div class=\"form-group\">\n							<label for=\"previousProgram\" class=\"col-sm-3 control-label blue bolder\">Προηγούμενη Ένταξη</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'class': ("form-control navbar-form-control"),
        'id': ("previousProgram"),
        'prompt': ("Επιλέξτε δομή"),
        'content': ("model.programs"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'selectionBinding': ("previousProgram")
      },hashTypes:{'class': "STRING",'id': "STRING",'prompt': "STRING",'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'selectionBinding': "STRING"},hashContexts:{'class': depth0,'id': depth0,'prompt': depth0,'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'selectionBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<div class=\"col-sm-offset-7 col-sm-3\">\n							<button type=\"submit\" class=\"btn btn-sm btn-primary\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "advancedSearch", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n								<i class=\"ace-icon fa fa-search\"></i>\n								Αναζήτηση\n							</button>\n							<button class=\"btn btn-warning btn-sm\" type=\"button\" title=\"Αναζήτηση\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "clearAdvancedSearch", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n								Καθαρισμός αναζητησης\n							</button>\n						</div>\n						<div class=\"col-xs-1\">\n							<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n						</div>\n					</div>\n				</div>\n\n			</form>\n\n		</div>\n		<br>\n		<div class=\"row\">\n			<div class=\"col-xs-12\">\n				<div class=\"table-header\">Αποτελέσματα Αναζήτησης </div>\n			</div>\n		</div>\n		<div class=\"row\">\n			<div class=\"col-xs-12\">\n\n				");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "persons-search-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n			</div>\n		</div>\n	</div>\n\n	<div class=\"tab-pane\" id=\"person_group_tab\">\n		<div class=\"row\">\n			<div class=\"col-xs-10 col-xs-offset-1\">\n				<div class=\"widget-box widget-color-blue2\">\n					<div class=\"widget-header\">\n						<h4 class=\"widget-title lighter smaller\">Ομάδες Ατόμων</h4>\n					</div>\n					<div class=\"widget-body\">\n						<div class=\"panel-group\" id=\"accordion\">\n							");
      stack1 = helpers['if'].call(depth0, "groups", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n</div>\n\n");
      return buffer;
      
    });
  });