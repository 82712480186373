define("front/helpers/new-line", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Handlebars.makeBoundHelper(function (text) {
      if (!text) {
        return text;
      }
      return new Ember.Handlebars.SafeString(text.replace(/\n/g, "<br>"));
    });
  });