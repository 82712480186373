define("front/routes/application", 
  ["simple-auth/mixins/application-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ApplicationRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(ApplicationRouteMixin, {
      activate: function () {
        this._super();
        if (!this.get("session").isAuthenticated) {
          //add login body class
          Ember.$("body").addClass("login-layout blur-login");
        }
      },

      model: function () {
        if (this.get("session").isAuthenticated) {
          //remove login body class
          Ember.$("body").removeClass("login-layout blur-login");
          Ember.$("body").addClass("no-skin");


          return Ember.RSVP.hash({
            user: this.store.find("user", this.get("session.user_id"))
          });
        }
      },
      afterModel: function (model) {
        if (this.get("session").isAuthenticated) {
          this.controllerFor("application").set("errorMessage", "");
          this.controllerFor("application").set("currentUser", model.user);

          this.controllerFor("application").set("currentUserName", this.get("session.user_name"));
          this.controllerFor("application").set("currentUserId", this.get("session.user_id"));

          var userUnits = Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
            content: model.user.get("units"),
            sortProperties: ["programName", "fullTitle"]
          });
          this.controllerFor("application").set("userUnits", userUnits);

          var units = model.user.get("units");

          var storedUnitId = window.localStorage.getItem("currentUnitId");
          var selectedUnit = storedUnitId ? units.filterBy("id", storedUnitId).get("firstObject") : units.get("firstObject");

          this.controllerFor("application").set("selectedUnit", selectedUnit);

          var self = this;

          this.store.find("notification", { unit_id: units.get("firstObject").id, unseen: 1 }).then(function (value) {
            self.controllerFor("application").set("notifications", value);

            var meta = self.store.metadataFor("notification");
            self.controllerFor("application").set("notifications_unseen_cnt", meta.unseen);
          });


          this.store.find("program", { unit_id: units.get("firstObject").id }).then(function (value) {
            self.controllerFor("application").set("program_image_url", value.get("firstObject").get("imageUrl"));
            self.controllerFor("application").set("current_program_id", value.get("firstObject").get("id"));
          });

          this.store.find("structure", { unit_id: units.get("firstObject").id }).then(function (value) {
            self.controllerFor("application").set("structure_name", value.get("firstObject").get("name"));
          });
        }
      },

      actions: {
        sessionAuthenticationFailed: function (error) {
          //var message = JSON.parse(error);
          //console.log(error);
          this.controllerFor("application").set("errorMessage", error.error);
        },
        sessionAuthenticationSucceeded: function () {
          this.refresh();
          this.transitionTo("dashboard");
        },
        reloadDash: function () {
          var self = this;
          var storedUnitId = window.localStorage.getItem("currentUnitId");
          var selectedUnitId = storedUnitId ? storedUnitId : this.controllerFor("application").get("selectedUnit.id");
          var currentUserId = this.controllerFor("application").get("currentUserId");

          $("body").append("<div id=\"loading\" class=\"loading-center\">Loading...<div class=\"loading-center\"></div></div>");

          Ember.RSVP.hash({
            structure: this.store.find("structure", { unit_id: selectedUnitId }),
            program: this.store.find("program", { unit_id: selectedUnitId }),
            user: this.store.find("user", this.get("session.user_id")),
            programs: this.store.find("program"),
            notifications: this.store.find("notification", { unit_id: selectedUnitId, unseen: 1 }),
            personGroups: this.store.find("personGroup", { user_id: currentUserId, unit_id: selectedUnitId })
          }).then(function (response) {
            $("#loading").remove();

            self.controllerFor("dashboard").set("model", response);
            self.controllerFor("application").set("notifications", response.notifications);
            var meta = self.store.metadataFor("notification");
            self.controllerFor("application").set("notifications_unseen_cnt", meta.unseen);
            self.controllerFor("application").set("structure_name", response.structure.get("firstObject").get("name"));
            self.controllerFor("application").set("program_image_url", response.program.get("firstObject").get("imageUrl"));
            self.transitionTo("dashboard");
          });
        },
        showModal: function (name, content) {
          this.controllerFor(name).set("content", content);
          this.render(name, {
            into: "application",
            outlet: "modal"
          });
        },
        removeModal: function () {


          this.disconnectOutlet({
            outlet: "modal",
            parentView: "application"
          });
        }

      }
    });
  });