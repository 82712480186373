define("front/views/element-history-table", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.content.[]"),

      didInsertElement: function () {
        var self = this;

        this.$().on("click", "#showEvent", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var pers_id = $(this).attr("data-person");
          self.get("controller").transitionTo("event", pers_id, event_id);
          //self.get('controller').send('showEvent', pers_id, event_id);
        });

        this.$().on("click", "#downloadFile", function (event) {
          event.preventDefault();
          var docname = $(this).attr("data-docname");
          var pers_id = $(this).attr("data-person");

          self.get("controller").send("downloadFile", pers_id, docname);
        });

        this.$().on("click", "#showDoc", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var pers_id = $(this).attr("data-person");
          self.get("controller").transitionTo("document.edit", pers_id, event_id);
        });

        this.$().on("click", "#deleteDoc", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "doc", name);
        });

        this.$().on("click", "#showFrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-forms", event_id);
        });

        this.$().on("click", "#deleteFrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "frm", name);
        });

        this.$().on("click", "#showPrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-parameters", event_id);
        });

        this.$().on("click", "#deletePrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "prm", name);
        });

        this.$().on("click", "#showGrp", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-form-groups", event_id);
        });

        this.$().on("click", "#showFrmHistory", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var pers_id = $(this).attr("data-person");

          var routeUrl = ENV.APP.frontendUrl + ("/form-history/" + pers_id + "/" + event_id);
          console.log(routeUrl);
          window.open(routeUrl);
          //self.get('controller').transitionTo('person-form-groups', event_id);
        });

        this.$().on("click", ".favourite-event", function (event) {
          event.preventDefault();

          var event_id = $(this).attr("data-event");
          var fclass = $(this).attr("data-class");

          var newClass = fclass === "fa-star" ? "fa-star-o" : "fa-star";
          //var name = $(this).attr('data-name');

          self.get("controller").send("favouriteEvent", event_id, fclass, newClass);

          //$("#favouriteEvent" + event_id + ' i').removeClass(fclass).addClass(newClass);
          //$(this).attr('data-class', newClass);
          //self.get('controller').send('showDeleteModal', event_id, 'prm', name);
        });

        this.$().on("click", ".favourite-form", function (event) {
          event.preventDefault();

          var event_id = $(this).attr("data-event");
          var fclass = $(this).attr("data-class");

          var newClass = fclass === "fa-star" ? "fa-star-o" : "fa-star";
          self.get("controller").send("favouriteForm", event_id, fclass, newClass);
        });

        this.$().on("click", ".favourite-doc", function (event) {
          event.preventDefault();

          var event_id = $(this).attr("data-event");
          var fclass = $(this).attr("data-class");

          var newClass = fclass === "fa-star" ? "fa-star-o" : "fa-star";
          self.get("controller").send("favouriteDoc", event_id, fclass, newClass);
        });

        var data = [];
        if (this.get("controller.content")) {
          data = this.get("controller.content").getEach("_data");
        }

        this.$().dataTable({
          bProcessing: true,
          aaSorting: [],
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          bAutoWidth: false,
          aaData: data,
          iDisplayLength: 10,
          fnRowCallback: function (nRow, aData) {
            var $nRow = $(nRow); // cache the row wrapped up in jQuery

            if (aData.name.indexOf("***") !== -1) {
              $("td:eq(2)", nRow).addClass("crisis");
            }
            return nRow;
          },

          aoColumns: [{ sTitle: "Τύπος", mData: "type" }, { sTitle: "Ημερομηνία", mData: "date" }, { sTitle: "Περιγραφή", mData: "name" }, {
            mdata: null,
            mRender: function (data, type, row) {
              var readonly = !(self.get("controller.currentUser.isAdmin") || self.get("controller.currentUser.id") === row.user_create);
              var result = "<div class='action-buttons'>";
              var favourite_class = row.favourite ? "fa-star" : "fa-star-o";
              switch (row.code) {

                case "EVN":
                  // var class = row.name.indexOf("***") !=-1 ? 'crisis' : '';
                  result += "<a class='favourite-event' id='favouriteEvent" + row.event_id + "' href='#' title='Δημοφιλές' data-class='" + favourite_class + "' data-event='" + row.event_id + "'><i class='ace-icon fa " + favourite_class + " bigger-130'></i></a>";
                  result += "<a class='blue' id='showEvent' href='#' title='Εμφάνιση Γεγονότος' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (row.filename) {
                    result += "<a class='blue' id='downloadFile' data-docname='" + row.filename + "' data-person='" + row.pers_id + "'><i class='ace-icon fa fa-download bigger-130'></i></a>";
                  }
                  result += "</div>";
                  break;
                case "DOC":
                  result += "<a class='favourite-doc' id='favouriteDoc" + row.event_id + "' href='#' title='Δημοφιλές' data-class='" + favourite_class + "' data-event='" + row.event_id + "'><i class='ace-icon fa " + favourite_class + " bigger-130'></i></a>";
                  result += "<a class='blue' id='showDoc' href='#' title='Εμφάνιση Εγγράφου' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#' class='red' id='deleteDoc' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "FRM":
                  if (!self.get("controller.currentUser.isVolunteer")) {
                    result += "<a class='blue' id='showFrmHistory' href='#' title='Εμφάνιση Ιστορικού' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                    result += "<i class='ace-icon fa fa-history bigger-130'></i></a>";
                  }

                  result += "<a class='favourite-form' id='favouriteForm" + row.event_id + "' href='#' title='Δημοφιλές' data-class='" + favourite_class + "' data-event='" + row.event_id + "'><i class='ace-icon fa " + favourite_class + " bigger-130'></i></a>";
                  result += "<a class='blue' id='showFrm' href='#' title='Εμφάνιση Φόρμας' data-name='" + row.name + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#'' class='red' id='deleteFrm' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "PRM":
                  result += "<a class='blue' id='showPrm' href='#' title='Εμφάνιση Παραμέτρου' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#'' class='red' id='deletePrm' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "GRP":
                  result += "<a class='blue' id='showGrp' href='#' title='Εμφάνιση Γκρούπ' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a></div>";
                  break;
              }
              return result;
            }
          }]
        });
      }
    });
  });