define("front/routes/form-history", 
  ["front/config/environment","simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];
    var Ember = __dependency3__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        var person_id = params.person_id;
        var person_form_id = params.person_form_id;
        // console.log(person_id, person_form_id);
        // //return axios.get(ENV.APP.backendUrl + `/person/${person_id}/formHistory/${person_form_id}`);
        // return $.getJSON(ENV.APP.backendUrl + `/person/${person_id}/formHistory/${person_form_id}`, (formHistory) => {
        //     return formHistory;
        // });


        return Ember.RSVP.hash({
          person_id: person_id,
          person_form_id: person_form_id
        });
      },


      setupController: function (controller, model) {
        var _this = this;
        //controller.set('formName', null);
        //controller.set('personName', null);
        controller.set("personId", model.person_id);
        $("body").append("<div id=\"loading\" class=\"loading-center\">Loading...<div class=\"loading-center\"></div></div>");
        $.getJSON(ENV.APP.backendUrl + ("/person/" + model.person_id + "/formHistory/" + model.person_form_id), function (formHistory) {
          $("#loading").remove();

          if (formHistory.length) {
            controller.set("formName", formHistory[0].name);
            controller.set("personName", formHistory[0].personName);

            var transformed = _this.transformData(formHistory);
            controller.set("firstForm", formHistory[0]);

            controller.set("header", transformed.header);
            controller.set("data", transformed.data);
            console.log("saasasa", transformed.data);
          }
        }, function (e) {
          $("#loading").remove();
        });
      },

      transpose: function (matrix) {
        return matrix.reduce(function (prev, next) {
          return next.map(function (item, i) {
            return (prev[i] || []).concat(next[i]);
          });
        }, []);
      },


      transformData: function (formHistory) {
        if (!formHistory) {
          return {
            header: [],
            data: []
          };
        }

        var data = [[]];
        var header = [];



        formHistory[0].questions.forEach(function (question) {
          data[0].push(question.questionText);
        });

        formHistory.forEach(function (form) {
          var headerData = {
            formId: form.id,
            dateString: form.dateCreatedString,
            formUser: form.formUser
          };

          header.push(headerData);

          var row = [];
          form.questions.forEach(function (question) {
            var answer = question.answers.map(function (answer) {
              if (answer.answerText) {
                return "" + answer.answerText;
              }
              return null;
            }).join(", ");

            row.push(answer);
          });

          data.push(row);
        });

        return {
          header: header,
          data: this.transpose(data)
        };
      }
    });
  });