define("front/templates/person/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Επεξεργασία Φακέλου</h3>\n			");
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Δημιουργία Νέου Φακέλου</h3>\n			");
      }

    function program5(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n					");
      data.buffer.push(escapeExpression((helper = helpers['flash-message'] || (depth0 && depth0['flash-message']),options={hash:{
        'type': ("alert alert-danger"),
        'message': ("Η ενέργεια δεν πραγματοποιήθηκε.")
      },hashTypes:{'type': "STRING",'message': "STRING"},hashContexts:{'type': depth0,'message': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "flash-message", options))));
      data.buffer.push("\n					<div class=\"space-20\"></div>\n				");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("lastName"),
        'id': ("lastName")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("lastName"),
        'id': ("lastName"),
        'focus-out': ("findMatches")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'focus-out': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'focus-out': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.lastName.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.lastName2.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("firstName"),
        'id': ("firstName")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program17(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("firstName"),
        'id': ("firstName"),
        'focus-out': ("findMatches")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'focus-out': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'focus-out': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program19(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.firstName.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program21(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.firstName2.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program23(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.fatherName.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program25(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.motherName.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program27(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("amka"),
        'id': ("amka")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program29(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("amka"),
        'id': ("amka"),
        'focus-out': ("findMatches")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'focus-out': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'focus-out': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program31(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.amka.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program33(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("afm"),
        'id': ("afm")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program35(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("afm"),
        'id': ("afm"),
        'focus-out': ("findMatches")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'focus-out': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'focus-out': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program37(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.afm.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program39(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.birthDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program41(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							<div class=\"form-group\">\n								<label for=\"isMember\" class=\"col-sm-3 control-label\">Ανάθεση:</label>\n								<div class=\"col-sm-2\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("isMember"),
        'id': ("isMember"),
        'checked': ("isMember"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n							</div>\n\n							");
      stack1 = helpers['if'].call(depth0, "isMember", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(42, program42, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program42(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"form-group\">\n								<label for=\"currentUnit\" class=\"col-sm-3 control-label\">Ένταξη:\n								</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'class': ("form-control navbar-form-control sbox-unit input-lg"),
        'content': ("controllers.application.currentUser.units"),
        'optionValuePath': ("content.id"),
        'disabled': (true),
        'optionLabelPath': ("content.name"),
        'selectionBinding': ("defaultUnit")
      },hashTypes:{'class': "STRING",'content': "ID",'optionValuePath': "STRING",'disabled': "BOOLEAN",'optionLabelPath': "STRING",'selectionBinding': "STRING"},hashContexts:{'class': depth0,'content': depth0,'optionValuePath': depth0,'disabled': depth0,'optionLabelPath': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n							</div>\n						</div>\n\n						");
      stack1 = helpers['if'].call(depth0, "defaultUnit.autoPhysfiles", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(45, program45, data),fn:self.program(43, program43, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						<div class=\"form-group\">\n							<label for=\"startDate\" class=\"col-sm-3 control-label\">Ημ/νία Ένταξης:</label>\n							<div class=\"col-sm-6\">\n								<div class=\"input-group date\" id=\"date-today\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("startDate"),
        'id': ("startDate")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n									<span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n								</div>\n							</div>\n							<div class=\"col-sm-12\"></div>\n							<div class=\"col-sm-6 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.startDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(48, program48, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n						");
      return buffer;
      }
    function program43(depth0,data) {
      
      
      data.buffer.push("\n						");
      }

    function program45(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n						<div class=\"form-group\">\n								<label for=\"physfile_code\" class=\"col-sm-3 control-label\">Κωδικος Φυσ. Φακ.:</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("physfile_code"),
        'id': ("physfile_code")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.physfile_code.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(46, program46, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n						");
      return buffer;
      }
    function program46(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.physfile_code.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program48(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.startDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n								");
      return buffer;
      }

    function program50(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n								\n								<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "edit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ενημέρωση</button>  \n\n								\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(51, program51, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "dashboard", options) : helperMissing.call(depth0, "link-to", "dashboard", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								");
      return buffer;
      }
    function program51(depth0,data) {
      
      
      data.buffer.push("\n								Επιστροφή\n								");
      }

    function program53(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n								<button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(51, program51, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "dashboard", options) : helperMissing.call(depth0, "link-to", "dashboard", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								<button class=\"btn btn-warning\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "dummyData", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ελλιπή Στοιχεία</button>\n								");
      return buffer;
      }

    function program55(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<div class=\"col-sm-6\">\n						");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "persons-create-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n					</div>\n					");
      return buffer;
      }

    function program57(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n<div class=\"col-xs-4 col-xs-offset-4\">\n	<div class=\"alert alert-danger validation-alert\" role=\"alert\">\n		");
      stack1 = helpers._triageMustache.call(depth0, "restError", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n	</div>\n</div>\n");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				");
      stack1 = helpers['if'].call(depth0, "flashMessage", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n				<form class=\"form-horizontal\" role=\"form\">\n					<div class=\"row\">\n						<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("editMode:col-sm-12:col-sm-6")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n							<div class=\"form-group\">\n								<label for=\"lastName\" class=\"col-sm-3 control-label\">Επώνυμο:</label>\n								<div class=\"col-sm-9\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(9, program9, data),fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.lastName.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"lastName2\" class=\"col-sm-3 control-label\">Επώνυμο 2:</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("lastName2"),
        'id': ("lastName2")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.lastName2.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"firstName\" class=\"col-sm-3 control-label\">Όνομα:</label>\n								<div class=\"col-sm-9\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(17, program17, data),fn:self.program(15, program15, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.firstName.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(19, program19, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"firstName2\" class=\"col-sm-3 control-label\">Όνομα 2:</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("firstName2"),
        'id': ("firstName2")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.firstName2.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(21, program21, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"fatherName\" class=\"col-sm-3 control-label\">Πατρώνυμο:</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("fatherName"),
        'id': ("fatherName")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.fatherName.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(23, program23, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"motherName\" class=\"col-sm-3 control-label\">Μητρώνυμο:</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("motherName"),
        'id': ("motherName")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.motherName.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"amka\" class=\"col-sm-3 control-label\">ΑΜΚΑ:</label>\n								<div class=\"col-sm-9\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(29, program29, data),fn:self.program(27, program27, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.amka.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(31, program31, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"afm\" class=\"col-sm-3 control-label\">ΑΦΜ:</label>\n								<div class=\"col-sm-9\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(35, program35, data),fn:self.program(33, program33, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.afm.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(37, program37, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"currentUnit\" class=\"col-sm-3 control-label\">Φύλο:\n								</label>\n\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'class': ("form-control"),
        'content': ("genders"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'value': ("gender")
      },hashTypes:{'class': "STRING",'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'value': "ID"},hashContexts:{'class': depth0,'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"birthDate\" class=\"col-sm-3 control-label\">Ημ/νία Γεν.:</label>\n								<div class=\"col-sm-6\">\n									<div class=\"input-group date\">\n										");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("birthDate"),
        'id': ("birthDate")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n										<span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n									</div>\n								</div>\n								<div class=\"col-sm-12\"></div>\n								<div class=\"col-sm-6 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.birthDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(39, program39, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"currentUnit\" class=\"col-sm-3 control-label\">Ιδιότητα:\n								</label>\n								<div class=\"col-sm-9\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'class': ("form-control navbar-form-control sbox-unit input-lg"),
        'content': ("personTypes"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'prompt': ("Επελέξτε ιδιότητα"),
        'selectionBinding': ("selectedType")
      },hashTypes:{'class': "STRING",'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'class': depth0,'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n								</div>\n							</div>\n\n\n							");
      stack1 = helpers.unless.call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(41, program41, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n						<div class=\"form-group\">\n							<div class=\"col-sm-1\">\n								<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n							</div>\n							<div class=\"col-sm-11\">\n								");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(53, program53, data),fn:self.program(50, program50, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n					</div>\n					");
      stack1 = helpers.unless.call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(55, program55, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n				</div>\n			</form>\n		</div>\n	</div>\n</div>\n\n</div>\n");
      stack1 = helpers['if'].call(depth0, "restError", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(57, program57, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n");
      return buffer;
      
    });
  });