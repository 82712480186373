define("front/controllers/relationships/new", 
  ["ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      relationTypes: null,
      searchString: null,
      selectedPerson: null,
      selectedType: null,

      actions: {
        search: function () {
          var self = this;

          this.set("showSpinner", true);
          this.store.find("person", { searchString: this.get("searchString") }).then(function (persons) {
            self.set("persons", persons);
            self.set("searchString", null);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("persons", null);
            self.set("searchString", null);
            self.set("showSpinner", false);
          });
        },

        selectPerson: function (id) {
          var self = this;
          this.store.find("person", id).then(function (response) {
            self.set("selectedPerson", response);
          });
        },

        create: function () {
          var self = this;

          if (!this.get("selectedPerson")) {
            Notify.warning("Δεν έχετε επιλέξει άτομο", {
              closeAfter: 10000
            });
          } else {
            var newRelationship = this.store.createRecord("relationship", {
              pers1: this.get("person"),
              pers2: this.get("selectedPerson"),
              relationtype1: this.get("selectedType"),
              relationtype2: null
            });

            newRelationship.save().then(function (response) {
              Notify.success("Η σχέση αποθηκεύτηκε", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.send("reloadPerson");
              self.transitionToRoute("person", self.get("person.id"));
            }, function (error) {
              Notify.error(error.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
            });
          }
        }
      }
    });
  });