define("front/controllers/dashboard", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var DashboardController = Ember.Controller.extend({
      needs: "application",
      searchString: null,
      showSpinner: false,
      currentUnit: null,
      groups: null,
      selectedProgram: null,

      cuObs: (function () {
        this.set("currentUnit", this.get("controllers.application.selectedUnit"));
      }).observes("controllers.application.selectedUnit"),

      /*program_image_url: function(){
      	if(!Ember.isEmpty(this.get('model.program'))){
      		var firstProgram = this.get('model.program').get('firstObject');
      		return firstProgram.get('image_url');
      	}
      }.property('model.program'),*/

      structure_name: (function () {
        if (!Ember.isEmpty(this.get("model.structure"))) {
          var firstStructure = this.get("model.structure").get("firstObject");
          return firstStructure.get("name");
        }
      }).property("model.structure"),

      actions: {
        printUnitGroupInfo: function (group) {
          var data;
          var self = this;

          this.send("showModal", "print-modals/print-wait");
          switch (group.get("id")) {
            case "100000001":
              $.fileDownload(ENV.APP.backendUrl + "/reports/unitGroup/" + self.get("currentUnit.id"), {
                httpMethod: "POST",
                data: {
                  Authorization: "Bearer " + self.session.get("access_token")
                }
              }).done(function () {
                $(".modal").modal("hide");
              }).fail(function () {
                $(".modal").modal("hide");
              });
              break;
            default:
              $.fileDownload(ENV.APP.backendUrl + "/reports/printGroup/" + group.get("id"), {
                httpMethod: "POST",
                data: {
                  Authorization: "Bearer " + self.session.get("access_token")
                }
              }).done(function () {
                $(".modal").modal("hide");
              }).fail(function () {
                $(".modal").modal("hide");
              });
          }
        },

        personGroups: function () {
          var self = this;

          this.store.find("personGroup", { user_id: this.get("currentUser"), unit_id: this.get("currentUnit.id") }).then(function (response) {
            self.set("groups", response);
          });
        },
        clearSearch: function () {
          this.set("searchResults", null);
          this.set("searchString", null);
        },

        clearAdvancedSearch: function () {
          this.set("searchResults", null);
          this.set("physfile", null);
          this.set("ketheaCode", null);
          this.set("firstName", null);
          this.set("lastName", null);
          this.set("fatherName", null);
          this.set("motherName", null);
          this.set("amka", null);
          this.set("afm", null);
          this.set("birthDate", null);
          this.set("isAddict", null);
          this.set("isFamily", null);
          this.set("previousProgram", null);
          this.set("selectedProgram", null);
          this.set("isNursing", null);
          this.set("isOfeloumenos", null);

          this.set("isChild", null);
          this.set("isEpitokos", null);
          this.set("isAnadoxosParent", null);
          this.set("isThetosParent", null);
          this.set("isEthelontis", null);
          this.set("isFamilyMember", null);
          this.set("isAllo", null);
        },

        search: function () {
          var self = this;
          this.set("showSpinner", true);
          this.store.find("person", { searchString: this.get("searchString") }).then(function (persons) {
            var data = persons.map(function (person) {
              var status = person.get("currentUnit") === null ? null : "" + person.get("currentUnit.programName") + " / " + person.get("currentUnit.name");
              return {
                id: person.get("id"),
                combinedLastName: person.get("combinedLastName"),
                combinedFirstName: person.get("combinedFirstName"),
                fatherName: person.get("fatherName"),
                displayPhysfiles: person.get("displayPhysfiles"),
                ketheaCode: person.get("ketheaCode"),
                birthDate: person.get("birthDate"),
                amka: person.get("amka"),
                afm: person.get("afm"),
                status: status
              };
            });

            self.set("searchResults", data);
            //self.set('searchString',null);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("searchResults", null);
            self.set("searchString", null);
            self.set("showSpinner", false);
          });
        },

        advancedSearch: function () {
          var self = this;
          this.set("showSpinner", true);

          this.store.find("person", {
            searchType: "1",
            physfile: this.get("physfile"),
            ketheaCode: this.get("ketheaCode"),
            firstName: this.get("firstName"),
            lastName: this.get("lastName"),
            fatherName: this.get("fatherName"),
            motherName: this.get("motherName"),
            amka: this.get("amka"),
            afm: this.get("afm"),
            selectedProgram: this.get("selectedProgram.id") ? this.get("selectedProgram.id") : null,
            birthDate: this.get("birthDate"),
            isAddict: this.get("isAddict") ? 1 : "",
            isFamily: this.get("isFamily") ? 1 : "",
            isOfeloumenos: this.get("isOfeloumenos") ? 1 : "",


            isChild: this.get("isChild") ? 1 : "",
            isEpitokos: this.get("isEpitokos") ? 1 : "",
            isAnadoxosParent: this.get("isAnadoxosParent") ? 1 : "",
            isThetosParent: this.get("isThetosParent") ? 1 : "",
            isEthelontis: this.get("isEthelontis") ? 1 : "",
            isFamilyMember: this.get("isFamilyMember") ? 1 : "",
            isAllo: this.get("isAllo") ? 1 : "",
            isNursing: this.get("isNursing") ? 1 : "",

            previousProgram: this.get("previousProgram.id") }).then(function (persons) {
            var data = persons.map(function (person) {
              var status = person.get("currentUnit") === null ? null : "" + person.get("currentUnit.programName") + " / " + person.get("currentUnit.name");
              return {
                id: person.get("id"),
                combinedLastName: person.get("combinedLastName"),
                combinedFirstName: person.get("combinedFirstName"),
                fatherName: person.get("fatherName"),
                displayPhysfiles: person.get("displayPhysfiles"),
                ketheaCode: person.get("ketheaCode"),
                birthDate: person.get("birthDate"),
                amka: person.get("amka"),
                afm: person.get("afm"),
                status: status
              };
            });

            self.set("searchResults", data);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("searchResults", null);
            self.set("showSpinner", false);
          });
        },

        gotoPerson: function (id) {
          this.transitionToRoute("person", id);
        }
      }

    });

    __exports__["default"] = DashboardController;
  });