define("front/controllers/person-group/edit", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      personGroup: null,
      userCurrentUnit: null,
      activation: true,
      showSpinner: false,
      canValidate: false,

      selectedPersons: Em.A([]),
      remainingPersons: Em.computed.setDiff("structurePersons", "selectedPersons"),
      remainingPersonsLength: Em.computed.alias("remainingPersons.length"),

      activationFlag: (function () {
        return this.get("activation");
      }).observes("activation"),


      isSystemGroup: (function () {
        var id = this.get("model.id");

        if (id === "100000001" || id === "100000002" || id === "100000003") {
          return true;
        }
        return false;
      }).property("model.id"),

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε το όνομα της ομάδας" }
        }
      },

      actions: {
        save: function () {
          var self = this;

          var model = this.get("model");
          model.save().then(function (response) {
            Notify.success("Η αλλαγή σας  αποθηκεύτηκε", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          }, function (error) {
            model.rollback();
            Notify.warning("Η αλλαγή σας δεν αποθηκεύτηκε", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          });
        },

        addPerson: function (item, elemetName, senderElement) {
          var self = this;

          var selectedPersons = this.get("selectedPersons");
          var person = this.get("structurePersons").findBy("code", item.code);

          if (!selectedPersons.contains(person)) {
            $.ajax({
              url: ENV.APP.backendUrl + "/personGroups/" + self.get("model.id") + "/personToGroup",
              type: "POST",
              data: JSON.stringify(person)
            }).then(function (response) {
              selectedPersons.pushObject(person);
            }, function (error) {
              Notify.warning("Το άτομο δεν προστέθηκε στη ομάδα", {
                closeAfter: 3000 // or set to null to disable auto-hiding
              });
            });
          }
        },

        clickAddPerson: function (id) {
          var self = this;

          var selectedPersons = this.get("selectedPersons");
          var person = this.get("structurePersons").filterBy("id", id).get("firstObject");

          if (!selectedPersons.contains(person)) {
            $.ajax({
              url: ENV.APP.backendUrl + "/personGroups/" + self.get("model.id") + "/personToGroup",
              type: "POST",
              data: JSON.stringify({ id: id })
            }).then(function (response) {
              console.log(person);
              selectedPersons.pushObject(person);
            }, function (error) {
              Notify.warning("Το άτομο δεν προστέθηκε στη ομάδα", {
                closeAfter: 3000 // or set to null to disable auto-hiding
              });
            });
          }
        },

        removePerson: function (id) {
          console.log(id);
          var self = this;
          var selectedPersons = this.get("selectedPersons");
          var person = this.get("selectedPersons").filterBy("id", id).get("firstObject");

          $.ajax({
            url: ENV.APP.backendUrl + "/personGroups/" + self.get("model.id") + "/removePersonFromGroup",
            type: "POST",
            data: { pers_id: person.id }
          }).then(function (response) {
            selectedPersons.removeObject(person);
          }, function (error) {
            Notify.warning("Το άτομο δεν διαγράφηκε απο την ομάδα", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });