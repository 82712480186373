define("front/templates/person/simple-merge", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n	<div class=\"col-sm-12 col-md-12 col-xs-12\">\n		<div class=\"widget-box\">\n			<div class=\"widget-header\">\n				<h4 class=\"widget-title smaller\"> Συγχώνευση φακέλων στο άτομο ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n			</div>\n			<div class=\"widget-body\">\n				<div class=\"widget-main\">\n					<div class=\"alert alert-warning\">\n						<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n						Ο φάκελος των επιλεγμένου μέλους θα συγχωνευτεί με του του τρέχοντος.\n					</div>\n\n					<div class=\"widget-box\">\n\n						<div class=\"widget-header widget-header-small\">\n							<h4 class=\"widget-title blue smaller\">\n								Συγχώνευση\n							</h4>\n						</div>\n\n						<div class=\"widget-body\">\n							<div class=\"widget-main\">\n\n								<form class=\"form-horizontal\" role=\"form\">\n									<div class=\"row\">\n										<div class=\"col-sm-12 col-md-12 col-xs-12\">\n											<div class=\"profile-user-info profile-user-info-striped\">\n				    \n												<div class=\"profile-info-row\">\n													<div class=\"profile-info-name col-md-5\">Φάκελος προορισμού:</div>\n													<div class=\"profile-info-value col-md-7\">\n														<div class=\"green big\" class=\"form-control\" id=\"personLink\">");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n													</div>\n												</div>\n\n												<div class=\"profile-info-row\">\n													<div class=\"profile-info-name col-md-5\">Φάκελος προς συγχώνευση:</div>\n													<div class=\"profile-info-value col-md-7\">\n														<div class=\"red big\" class=\"form-control\" id=\"personLink\">");
      stack1 = helpers._triageMustache.call(depth0, "selectedPerson.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n													</div>\n												</div>\n\n											</div>\n										</div>\n									</div>\n\n									<div class=\"form-actions center\">\n										<button class=\"btn btn-sm btn-danger\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "warning", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Συγχώνευση</button>\n										");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-warning btn-sm"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									</div>\n								</form>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"space-20\"></div>\n					<div class=\"row\">\n						<div class=\"col-xs-10 col-xs-offset-1\">\n							<div class=\"input-group\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("text"),
        'class': ("form-control"),
        'value': ("searchString"),
        'action': ("search"),
        'placeholder': ("Αναζήτηση")
      },hashTypes:{'type': "STRING",'class': "STRING",'value': "ID",'action': "STRING",'placeholder': "STRING"},hashContexts:{'type': depth0,'class': depth0,'value': depth0,'action': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								<span class=\"input-group-btn\">\n									<button class=\"btn btn-info btn-sm\" type=\"button\" title=\"Αναζήτηση\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "search", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n										<span class=\"glyphicon glyphicon-search\"></span>\n									</button>\n								</span>\n							</div><!-- /input-group -->\n						</div>\n						\n						<div class=\"col-xs-1\">\n							<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n						</div>\n					</div>\n\n					<div class=\"space-20\"></div>\n\n					<div class=\"row\">\n						<div class=\"col-xs-12\">\n							<div class=\"table-header\">Αποτελέσματα Αναζήτησης </div>\n						</div>\n					</div>\n\n					");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "persons.simplemerge-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n											Επιστροφή στο φάκελο\n										");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n	<div class=\"alert alert-danger\">\n		Ο χρήστης δεν έχει τα απαραίτητα δικαιώματα να πραγματοποιήσει συγχώνευση φακέλων<br>\n	</div>\n");
      }

      stack1 = helpers['if'].call(depth0, "isAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });