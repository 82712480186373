define("front/templates/person/revoke-service", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n	<div class=\"alert alert-success no-margin\">\n		Το άτομο ανήκει στην υπηρεσία <u>");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</u>\n	</div>\n");
      return buffer;
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n	<div class=\"alert alert-success no-margin\">\n		Το άτομο δεν ανήκει σε κάποια υπηρεσία\n	</div>\n");
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n						<div class=\"text-warning bigger-110 orange\">\n							<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n							Προσοχή θα διακόψετε την παροχή υπηρεσιών του μέλους για την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "user_current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n						<div class=\"space-20\"></div>\n						<form class=\"form-horizontal\" role=\"form\">\n\n            <div class=\"form-group\">\n              <label for=\"document_date\" class=\"col-sm-2 control-label\">Ημ/νία:</label>\n              <div class=\"col-sm-6\">\n                <div class=\"input-group date\" id=\"date-today\">\n                  ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("date"),
        'id': ("unit_reference_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                  <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n                </div>\n              </div>\n              <div class=\"col-sm-12\"></div>\n              <div class=\"col-sm-6 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n      \n            <div class=\"form-group\">\n              <div class=\"col-sm-offset-2 col-sm-6\">\n                \n	               <button class=\"btn btn-warning\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "revoke", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Διακοπή</button>\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            \n              </div>\n              <div class=\"col-sm-2\">\n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n              </div>\n            </div>\n          </form>\n					");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program8(depth0,data) {
      
      
      data.buffer.push("\n									Ακύρωση\n								");
      }

      data.buffer.push("\n");
      stack1 = helpers['if'].call(depth0, "person.currentUnit", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n<div class=\"space-20\"></div>\n<div class=\"row\">\n	<div class=\"col-xs-12\">\n		<div class=\"widget-box\">\n			<div class=\"widget-header\">\n				<h4 class=\"widget-title smaller\">Δήλωση παροχής υπηρεσιών απο την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "user_current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n			</div>\n			<div class=\"widget-body\">\n				<div class=\"widget-main\">\n\n					");
      stack1 = helpers['if'].call(depth0, "hasService", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n				</div>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });