define("front/templates/person/form-report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


      data.buffer.push("<div class=\"row\">\n	<div class=\"col-xs-12\">\n		<div class=\"widget-box\">\n			<div class=\"widget-header\">\n				<h4 class=\"widget-title smaller\">Αναφορά Φορμών</h4>\n			</div>\n			<div class=\"widget-body\">\n				<div class=\"widget-main\">\n                    <form class=\"form-horizontal\" role=\"form\">\n                        <div class=\"form-group\">\n                            <label for=\"date_from\" class=\"col-sm-3 control-label\">Ημ/νία από:</label>\n                            <div class=\"col-sm-4\">\n                                <div class=\"input-group date\" id=\"date_from\">\n                                    \n                                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateFrom")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                    \n                                    <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n                                </div>\n                            </div>\n                           \n                        </div>\n\n                        <div class=\"form-group\">\n                            <label for=\"date_to\" class=\"col-sm-3 control-label\">Ημ/νία έως:</label>\n                            <div class=\"col-sm-4\">\n                                <div class=\"input-group date\" id=\"date_to\">\n                                    \n                                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateTo")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                    \n                                    <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n                                </div>\n                            </div>\n                           \n                        </div>\n                        \n                        <div class=\"form-group\">\n                            <label for=\"date_to\" class=\"col-sm-3 control-label\">Φόρμες</label>\n                            <div class=\"col-sm-4\">\n                                \n                                    ");
      data.buffer.push(escapeExpression((helper = helpers['multiselect-checkboxes'] || (depth0 && depth0['multiselect-checkboxes']),options={hash:{
        'options': ("forms"),
        'labelProperty': ("name"),
        'selection': ("selectedForms")
      },hashTypes:{'options': "ID",'labelProperty': "STRING",'selection': "ID"},hashContexts:{'options': depth0,'labelProperty': depth0,'selection': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "multiselect-checkboxes", options))));
      data.buffer.push("\n                              \n                            </div>\n                        </div>\n\n                        <div class=\"form-group\">\n		                    <div class=\"col-sm-7 col-sm-offset-3\">\n                                <button class=\"btn btn-purple\"");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση Αναφοράς</button>\n                            </div>\n                        </div>\n                    </form>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n");
      return buffer;
      
    });
  });