define("front/router", 
  ["ember","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var config = __dependency2__["default"];

    var Router = Ember.Router.extend({
      location: config.locationType
    });

    Router.map(function () {
      this.route("component-test");
      this.route("helper-test");
      this.route("login");
      this.route("about");
      this.route("export");

      this.resource("appointments", function () {
        this.route("new");
      });
      this.resource("participations", { path: "participations" }, function () {
        this.resource("participation", { path: "/:day" }, function () {});
      });

      this.route("transitional-presences");
      this.route("trpr-report");


      this.resource("dashboard");
      this.resource("person-groups", function () {
        //this.route('index');
        this.resource("person-group", { path: "/:person_group_id" }, function () {
          this.route("edit");
        });
        this.route("new");
      });

      this.resource("administration", function () {
        this.resource("programs", function () {
          this.resource("program", { path: "/:program_id" }, function () {});
          this.route("new");
        });

        this.resource("units", function () {
          this.route("edit", { path: "/:unit_id" });
          this.route("new");
        });

        this.resource("parameters", function () {
          this.resource("parameter", { path: "/:parameter_id" }, function () {});
          this.route("new");
        });

        this.resource("forms", function () {
          this.resource("form", { path: "/:form_id" }, function () {
            this.resource("form-groups", { path: "form-groups" }, function () {});
            this.route("build");
          });
          this.route("new");
        });

        this.resource("form-parameter", { path: "form-parameter/:formparameter_id" }, function () {
          this.route("edit");
        });
      });

      this.resource("form-complete", { path: "forms/:form_id/form-complete/:person_id" });
      this.resource("official-complete", { path: "forms/:form_id/official-complete/:person_id" });

      this.resource("parameter-complete", { path: "parameters/:parameter_id/parameter-complete/:person_id" });

      this.resource("person-forms", { path: "person-forms/:person_form_id" });
      this.resource("form-history", { path: "form-history/:person_id/:person_form_id" });

      this.resource("person-parameters", { path: "person-parameters/:person_form_id" });

      this.resource("person-form-groups", { path: "person-form-groups/:form_group_id" });

      this.route("expeditions");

      this.resource("expedition-approaches", function () {
        //this.route('index');
        this.route("new");
        this.route("edit", { path: ":exap_id/edit" });
        this.route("report");
      });

      this.resource("expedition-exchanges", function () {
        //this.route('index');
        this.route("new");
        this.route("edit", { path: ":exex_id/edit" });
        this.route("report");
      });

      this.resource("helplines", function () {
        this.route("new");
        this.route("edit", { path: ":call_id/edit" });
        this.route("report");
        this.route("caller-report");
        this.route("player-report");
      });

      this.resource("ithaki-helplines", function () {
        this.route("new");
        this.route("edit", { path: ":call_id/edit" });
        this.route("report");
      });

      this.resource("users", function () {
        //this.route('index');
        this.route("new");
        this.route("edit", { path: ":user_id/edit" });
      });

      this.resource("persons", function () {
        this.resource("person", { path: "/:person_id" }, function () {
          this.route("person-history");
          this.route("edit", { path: "edit" });
          this.route("unit-assignment");
          this.route("unit-reference");
          this.route("closure");
          //  this.route('pehi-admin');
          this.route("accomplishment");
          this.route("graduation");
          this.route("transport");
          this.route("release");
          this.route("transition");
          this.route("assign-service");
          this.route("revoke-service");
          this.route("revoke-reference");
          this.route("ascertainment");
          this.route("pdf-ascertainment", { path: "/:pehi_id" });
          this.route("accept-reference");
          this.route("withdraw");
          this.route("termination");
          this.route("lock");
          this.route("search-officials");
          this.route("search-form");
          this.route("search-parameter");
          this.route("termination-reference");
          this.route("simple-merge");
          this.route("person-history");
          this.route("form-report");
          this.resource("events", { path: "events" }, function () {
            this.resource("event", { path: "/:event_id" }, function () {
              this.route("change-type");
            });
          });
          this.resource("pehi-admin", { path: "pehi-admin" }, function () {
            //this.route('edit' , { path: ':pehi_id/edit'}, function(){});
            this.resource("pehi", { path: ":pehi_id/edit" }, function () {
              this.route("edit-event", { path: ":pevn_id/pevn" });
              this.route("create-event", { path: "create-pevn/:type" });
            });
          });
          this.resource("relationships", { path: "relationships" }, function () {
            this.resource("relationship", { path: "/:rels_id" }, function () {
              this.route("edit");
            });
            this.route("new");
          });
          this.resource("physfiles", { path: "physfiles" }, function () {
            this.route("edit", { path: ":physfile_id/edit" });
            this.route("new");
          });
          this.resource("documents", { path: "documents" }, function () {
            this.resource("document", { path: "/:document_id" }, function () {
              this.route("edit");
            });
            this.route("new");
          });
        });

        this.route("new");
      });

      this.resource("templates", function () {
        this.route("new");
      });

      this.resource("reports", function () {
        this.route("synola");
        this.route("persons-synola");
        this.route("istoriko-xronou");
        this.route("events");
        this.route("unit");
        this.route("unit-general-stats");
        this.route("unit-presences-stats");
        this.route("imeres-paramonis");
        this.route("new-persons");
      });

      this.resource("notifications", function () {});

    });

    __exports__["default"] = Router;


    //this.route('new');
  });