define("front/routes/form-complete", 
  ["simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Ember = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        //var formModel = this.modelFor('form');
        var selectedUnit = this.controllerFor("application").get("selectedUnit");

        this.store.unloadAll("answer");
        this.store.unloadAll("choice");

        var self = this;
        var formPromise = this.store.find("form", params.form_id);

        return Ember.RSVP.all([formPromise]).then(function (form) {
          if (form[0].get("hasGroups")) {
            return Ember.RSVP.hash({
              form: form[0],
              groups: self.store.find("formGroup", {
                form_id: params.form_id
              }),
              parameters: null,
              person: self.store.find("person", params.person_id),
              users: self.store.find("user", { program_unit_id: selectedUnit.id })
            });
          } else {
            return Ember.RSVP.hash({
              form: form[0],
              groups: null,
              parameters: self.store.find("formParameter", { form_id: params.form_id }),
              person: self.store.find("person", params.person_id),
              users: self.store.find("user", { program_unit_id: selectedUnit.id })
            });
          }
        });
      },

      setupController: function (controller, model) {


        controller.set("form", model.form);
        controller.set("groups", model.groups);
        controller.set("parameters", model.parameters);
        controller.set("person", model.person);
        controller.set("users", model.users);
        controller.set("personForm", "");
        controller.set("formDate", "");
        controller.set("onlyParameter", false);
        controller.set("editForm", false);
      }
    });
  });