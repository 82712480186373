define("front/templates/form-complete", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Συμπλήρωση Παραμέτρου </h3>\n			");
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Συμπλήρωση Φόρμας - ");
      stack1 = helpers._triageMustache.call(depth0, "form.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n			");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n						<div class=\"col-xs-10 col-xs-offset-2\">\n							<label for=\"formDate\" class=\"col-sm-3 control-label\">Ημερομηνία:\n							</label>\n							<div class=\"col-sm-4\">\n								<div class=\"input-group date\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("formDate"),
        'id': ("formDate")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n									<span class=\"input-group-addon\"><i class=\"fa fa-calendar\">\n									</i></span>\n								</div>\n							</div>\n						</div>\n\n						<div class=\"col-xs-10 col-xs-offset-2\" style=\"margin-top:10px;\">\n							<label for=\"formUser\" class=\"col-sm-3 control-label\">Χρήστης:</label>\n							<div class=\"col-sm-4\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'class': ("form-control formUser"),
        'content': ("users"),
        'prompt': ("Επιλέξτε χρήστη"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'selectionBinding': ("selectedFormUser")
      },hashTypes:{'class': "STRING",'content': "ID",'prompt': "STRING",'optionValuePath': "STRING",'optionLabelPath': "STRING",'selectionBinding': "STRING"},hashContexts:{'class': depth0,'content': depth0,'prompt': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n\n						</div>\n					</div>\n\n						<div class=\"col-xs-10 col-xs-offset-2\">\n							<label for=\"selfComplete\" class=\"col-sm-3 control-label\">Αυτοχορηγούμενο:\n							</label>\n							<div class=\"col-sm-2\">\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("selfComplete"),
        'classNames': ("form-control selfComplete")
      },hashTypes:{'type': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n							</div>\n						</div>\n					");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n<div class=\"space-6\"></div>\n<div class=\"row\">\n	<div class=\"col-xs-12\">");
      stack1 = helpers._triageMustache.call(depth0, "form.header", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n</div>\n<div class=\"space-6\"></div>\n");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n			<div class=\"panel-group fg-accordion\" id=\"accordion\">\n				");
      stack1 = helpers.each.call(depth0, "group", "in", "groups", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n		");
      return buffer;
      }
    function program10(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n					<div class=\"panel panel-info\">\n\n						<div class=\" accordion-heading panel-heading \">\n							<h4 class=\"panel-title bigger\">\n								<a data-toggle=\"collapse\" data-parent=\"#accordion\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'href': ("group.hashGroupId")
      },hashTypes:{'href': "ID"},hashContexts:{'href': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n									<h3>");
      stack1 = helpers._triageMustache.call(depth0, "group.groupName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n								</a>\n							</h4>\n						</div>\n						<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("group.groupId")
      },hashTypes:{'id': "ID"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"panel-collapse collapse\">\n							<div class=\"panel-body well\">								\n							");
      stack1 = helpers.each.call(depth0, "parameter", "in", "group.formParameters", {hash:{
        'itemController': ("form-complete/form-complete-param")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							</div>\n						</div>\n\n					</div>\n				");
      return buffer;
      }
    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n								");
      stack1 = helpers['if'].call(depth0, "parameter.isType0", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								");
      stack1 = helpers['if'].call(depth0, "parameter.isType1", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								");
      stack1 = helpers['if'].call(depth0, "parameter.isType2", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(17, program17, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								");
      stack1 = helpers['if'].call(depth0, "parameter.isType3", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(19, program19, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								");
      stack1 = helpers['if'].call(depth0, "parameter.isType4", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(22, program22, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      return buffer;
      }
    function program12(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"space-6 col-xs-12\"></div>\n\n									<div class=\"col-xs-12\">\n										<h4 ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'style': ("parameter.nestLvlMargin")
      },hashTypes:{'style': "STRING"},hashContexts:{'style': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n\n											");
      stack1 = helpers._triageMustache.call(depth0, "parameter.numbering", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										</h4>\n									</div>\n\n									<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("parameter.nestLvlClass")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n\n										");
      stack1 = helpers.each.call(depth0, "parameter.question.answers", {hash:{
        'itemController': ("form-complete/form-complete-answer")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									</div>\n								");
      return buffer;
      }
    function program13(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n\n										");
      data.buffer.push(escapeExpression((helper = helpers.partial || (depth0 && depth0.partial),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "form-complete/form-complete-answers", options) : helperMissing.call(depth0, "partial", "form-complete/form-complete-answers", options))));
      data.buffer.push("\n\n										");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"col-xs-2\">\n										<h5>");
      stack1 = helpers._triageMustache.call(depth0, "parameter.numbering", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n									</div>\n\n								");
      return buffer;
      }

    function program17(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n									<div class=\"col-xs-2\">\n										<h5>");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n									</div>\n\n								");
      return buffer;
      }

    function program19(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n									");
      stack1 = helpers.each.call(depth0, "parameter.question.answers", {hash:{
        'itemController': ("form-complete/form-complete-answer")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(20, program20, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n								");
      return buffer;
      }
    function program20(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n\n										");
      data.buffer.push(escapeExpression((helper = helpers.partial || (depth0 && depth0.partial),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "form-complete/complete-answers-table", options) : helperMissing.call(depth0, "partial", "form-complete/complete-answers-table", options))));
      data.buffer.push("\n\n									");
      return buffer;
      }

    function program22(depth0,data) {
      
      
      data.buffer.push("\n									<div class=\"clearfix col-xs-12\"></div>\n								");
      }

    function program24(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n		<div class=\"well col-xs-12\">\n\n			");
      stack1 = helpers.each.call(depth0, "parameter", "in", "parameters", {hash:{
        'itemController': ("form-complete/form-complete-param")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n		");
      return buffer;
      }
    function program25(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "parameter.isType0", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(26, program26, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "parameter.isType1", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(29, program29, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "parameter.isType2", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(31, program31, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "parameter.isType3", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(33, program33, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "parameter.isType4", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(36, program36, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      return buffer;
      }
    function program26(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"space-6 col-xs-12\"></div>\n\n							<div class=\"col-xs-12\">\n								<h4 ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'style': ("parameter.nestLvlMargin")
      },hashTypes:{'style': "STRING"},hashContexts:{'style': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n\n									");
      stack1 = helpers._triageMustache.call(depth0, "parameter.numbering", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</h4>\n							</div>\n\n							<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("parameter.nestLvlClass")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n\n								");
      stack1 = helpers.each.call(depth0, "parameter.question.answers", {hash:{
        'itemController': ("form-complete/form-complete-answer")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(27, program27, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n							");
      return buffer;
      }
    function program27(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n\n								");
      data.buffer.push(escapeExpression((helper = helpers.partial || (depth0 && depth0.partial),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "form-complete/form-complete-answers", options) : helperMissing.call(depth0, "partial", "form-complete/form-complete-answers", options))));
      data.buffer.push("\n\n								");
      return buffer;
      }

    function program29(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"col-xs-2\">\n									<h5>");
      stack1 = helpers._triageMustache.call(depth0, "parameter.numbering", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n								</div>\n\n							");
      return buffer;
      }

    function program31(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n								<div class=\"col-xs-2\">\n									<h5>");
      stack1 = helpers._triageMustache.call(depth0, "parameter.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n								</div>\n\n							");
      return buffer;
      }

    function program33(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n								");
      stack1 = helpers.each.call(depth0, "parameter.question.answers", {hash:{
        'itemController': ("form-complete/form-complete-answer")
      },hashTypes:{'itemController': "STRING"},hashContexts:{'itemController': depth0},inverse:self.noop,fn:self.program(34, program34, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      return buffer;
      }
    function program34(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n\n								");
      data.buffer.push(escapeExpression((helper = helpers.partial || (depth0 && depth0.partial),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "form-complete/complete-answers-table", options) : helperMissing.call(depth0, "partial", "form-complete/complete-answers-table", options))));
      data.buffer.push("\n\n								");
      return buffer;
      }

    function program36(depth0,data) {
      
      
      data.buffer.push("\n								<div class=\"clearfix col-xs-12\"></div>\n							");
      }

    function program38(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n				");
      stack1 = helpers['if'].call(depth0, "form.isOfficial", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(41, program41, data),fn:self.program(39, program39, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			");
      return buffer;
      }
    function program39(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<button class=\"btn btn-purple\" id=\"saveButton\"\n					");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveForm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση & Εκτύπωση</button>\n				");
      return buffer;
      }

    function program41(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<button class=\"btn btn-primary\" id=\"saveButton\"\n					");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveForm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n				");
      return buffer;
      }

    function program43(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(44, program44, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			");
      return buffer;
      }
    function program44(depth0,data) {
      
      
      data.buffer.push("\n					Επιστροφή\n				");
      }

    function program46(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(47, program47, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person.search-form", "person.id", options) : helperMissing.call(depth0, "link-to", "person.search-form", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			");
      return buffer;
      }
    function program47(depth0,data) {
      
      
      data.buffer.push("\n				Επιστροφή\n				");
      }

    function program49(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n					");
      stack1 = helpers['if'].call(depth0, "form.isOfficial", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(52, program52, data),fn:self.program(50, program50, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n			");
      return buffer;
      }
    function program50(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<button class=\"btn btn-purple\"\n					");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printOfficial", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n				");
      return buffer;
      }

    function program52(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<button class=\"btn btn-purple\"\n					");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printForm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n				");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\" id=\"form_div\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			");
      stack1 = helpers['if'].call(depth0, "onlyParameter", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n\n				<form class=\"form-horizontal\" role=\"form\" id=\"my_form\">\n\n					<div class=\"row\">\n						<div class=\"col-xs-8 col-xs-offset-4 blue bolder\">\n							<h4>Ωφελούμενος: ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n						</div>\n						");
      stack1 = helpers.unless.call(depth0, "onlyParameter", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n				</div>\n\n");
      stack1 = helpers.unless.call(depth0, "onlyParameter", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n<div class=\"row\">\n	<div class=\"col-xs-12\">\n		");
      stack1 = helpers['if'].call(depth0, "hasGroups", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(24, program24, data),fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n	</div>\n</div>\n\n<div class=\"row\">\n	<div class=\"space-18\"></div>\n	<div class=\"form-group\">\n		<div class=\"col-sm-8 col-sm-offset-4\">\n\n			");
      stack1 = helpers.unless.call(depth0, "readonly", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(38, program38, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			");
      stack1 = helpers['if'].call(depth0, "editForm", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(46, program46, data),fn:self.program(43, program43, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n			");
      stack1 = helpers['if'].call(depth0, "showPrintButton", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(49, program49, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n	</div>\n</div>\n\n			</form>\n		</div>\n	</div>\n</div>\n\n</div>\n");
      return buffer;
      
    });
  });