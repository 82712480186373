define("front/controllers/person/merge-warning", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      needs: ["application"],

      actions: {
        merge: function () {
          var self = this;
          var data = { person_from: this.get("content.person_from.id"), person_to: this.get("content.person_to.id"), current_unit: this.get("controllers.application.selectedUnit.id") };
          if (this.get("content.person_from") === this.get("content.person_to")) {
            Notify.error("Προσπαθήσατε να συγχωνεύσετε το ίδιο άτομο", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            $(".modal").modal("hide");
          } else {
            $.ajax({
              url: ENV.APP.backendUrl + "/simpleMerge",
              type: "POST",
              contentType: "application/json",
              dataType: "json",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Η συγχώνευση πραγματοποιήθηκε", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.send("reloadPerson");
              self.transitionToRoute("person", self.get("content.person_to.id"));
            }, function (error) {
              Notify.error(error.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
            });

            $(".modal").modal("hide");
          }
        },

        closeModal: function () {
          this.send("removeModal");
        } }
    });
  });