define("front/controllers/person/edit", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.ObjectController.extend(PersonMixin, {

      needs: ["application"],

      showSpinner: false,
      flashMessage: false,
      property: null,

      genders: [{ name: "Άρρεν", id: "1" }, { name: "Θήλυ", id: "2" }],

      stopEditing: function () {
        if (this.transaction) {
          this.transaction.rollback();
          this.transaction = null;
        }
      },

      actions: {

        edit: function () {
          var person = this.get("model");
          person.set("nationality", this.get("selectedNationality"));
          person.set("type", this.get("selectedType"));

          person.set("isAddict", 1);
          person.set("isFamily", 0);
          person.set("isOther", 0);
          this.set("showSpinner", true);

          var self = this;

          person.save().then(function (person) {
            self.set("showSpinner", false);
            self.set("flashMessage", false);
            self.transitionToRoute("person", person.id);
          }, function (response) {
            self.set("showSpinner", false);
            self.set("flashMessage", true);
          });

        }
      }
    });
  });