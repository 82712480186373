define("front/templates/person/lock", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n  <div class=\"col-sm-12\"> \n    <div class=\"page-header\">\n      <h1>");
      stack1 = helpers._triageMustache.call(depth0, "model.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Κλείδωμα φακέλου μέλους</small></h1>\n      <div class=\"space-20\"></div>\n\n      <div class=\"text-warning bigger-110 orange\">\n				<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n				Έχετε επιλέξει να κλειδώσετε το άτομο ");
      stack1 = helpers._triageMustache.call(depth0, "model.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(".<br>\n				Εφόσον πραγματοποιήσετε την ενέργεια αυτή ο φάκελος του μέλους δεν θα είναι πια προσβάσιμος απο τους χρήστες του συστήματος\n			</div>\n			<div class=\"space-20\"></div>\n			<button class=\"btn btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "lockMember", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Κλείδωμα του μέλους</button>\n			");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    </div>\n  </div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n				Ακύρωση ενέργειας\n			");
      }

      stack1 = helpers['if'].call(depth0, "isAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });