define("front/templates/person", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n<div class=\"row\">\n    <div class=\"col-sm-12 col-md-12 col-xs-12\">\n\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n            <div class=\"page-header\">\n              <h1>");
      stack1 = helpers._triageMustache.call(depth0, "model.person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                <small><i class=\"ace-icon fa fa-angle-double-right\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>\n              </h1>\n                </div>\n        </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-sm-12 col-md-12 col-xs-12\">\n            <div class=\"profile-user-info profile-user-info-striped\">\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Όνομα</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.firstName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Επώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.lastName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Όνομα 2</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.firstName2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Επώνυμο 2</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.lastName2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Πατρώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.fatherName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Μητρώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.motherName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Κωδ. Φυσ. Φακ.</div>\n                        <div class=\"profile-info-value col-md-10\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.displayPhysfiles", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n              </div>\n\n            <div class=\"profile-info-row\">\n\n                    <div class=\"profile-info-name col-md-2\">ΑΦΜ</div>\n                    <div class=\"profile-info-value col-md-4\">\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.afm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n\n                    <div class=\"profile-info-name col-md-2\">Ημ/νία Γεν.</div>\n                    <div class=\"profile-info-value col-md-4\">\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.birthDate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n            </div>\n\n            <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Ιδιότητα</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "isBeingTreatedString", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      stack1 = helpers._triageMustache.call(depth0, "model.person.type.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">ΑΜΚΑ</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.amka", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                </div>\n\n                </div>\n            </div>\n        </div>\n        <div class=\"space-20\"></div>\n        <div class=\"alert alert-danger\">\n            Το άτομο είναι κλειδωμένο. Για να ξεκλειδώσετε τον φάκελο του μέλους παρακαλώ επικοινωνήστε με τον διαχειριστή του προγράμματος\n        </div>\n    </div>\n</div>\n");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n    <div class=\"alert alert-warning\">\n        Έχετε ρόλο διαχειριστή. <br>\n        <div class=\"space-20\"></div>\n        <button class=\"btn btn-warning\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "unLockMember", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ξεκλείδωμα φακέλους</button>\n    </div>\n");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n    <div class=\"col-sm-12 col-md-12 col-xs-12\">\n\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n            <div class=\"page-header\">\n              <h1>");
      stack1 = helpers._triageMustache.call(depth0, "model.person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                <small>\n                    <i class=\"ace-icon fa fa-angle-double-right\"></i>\n                     ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      stack1 = helpers['if'].call(depth0, "timeInCurrentUnit", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </small>\n              </h1>\n                </div>\n        </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-sm-12 col-md-12 col-xs-12\">\n            <div class=\"profile-user-info profile-user-info-striped\">\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Όνομα</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.firstName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Επώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.lastName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Όνομα 2</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.firstName2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Επώνυμο 2</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.lastName2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Πατρώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.fatherName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">Μητρώνυμο</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.motherName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n              </div>\n\n              <div class=\"profile-info-row\">\n\n                    <div class=\"profile-info-name col-md-2\">Κωδ. Φυσ. Φακ.</div>\n                    <div class=\"profile-info-value col-md-8\">\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.displayPhysfiles", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n\n              </div>\n\n             <div class=\"profile-info-row\">\n\n                    <div class=\"profile-info-name col-md-2\">ΑΦΜ</div>\n                    <div class=\"profile-info-value col-md-4\">\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.afm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n\n                    <div class=\"profile-info-name col-md-2\">Ημ/νία Γεν.</div>\n                    <div class=\"profile-info-value col-md-4\">\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.birthDate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n             </div>\n\n             <div class=\"profile-info-row\">\n\n                        <div class=\"profile-info-name col-md-2\">Ιδιότητα</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "isBeingTreatedString", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      stack1 = helpers._triageMustache.call(depth0, "model.person.type.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                        <div class=\"profile-info-name col-md-2\">AMKA</div>\n                        <div class=\"profile-info-value col-md-4\">\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "model.person.amka", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n                </div>\n\n                </div>\n            </div>\n        </div>\n        <div class=\"space-20\"></div>\n        <div class=\"row\">\n            <div class=\"col-md-3 col-sm-6\">\n                ");
      stack1 = helpers.unless.call(depth0, "isVolunteer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                ");
      stack1 = helpers['if'].call(depth0, "related", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                <div>\n                    <div class=\"col-sm-12 col-xs-12 offset-col-sm-1 label label-info label-xlg\">\n                        <b>Επιλογές</b>\n                    </div>\n                </div>\n\n                <div class=\"space-20\"></div>\n\n                <div>\n                    <ul class=\"list-unstyled spaced\" id=\"memberDetailsMenu\">\n                    ");
      stack1 = helpers.unless.call(depth0, "isVolunteer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(17, program17, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                    <li>\n                        <i class=\"ace-icon fa fa-users blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "relationships.new", options) : helperMissing.call(depth0, "link-to", "relationships.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n                    <li>\n                        <i class=\"ace-icon fa fa-pencil-square-o blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(27, program27, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "documents.new", options) : helperMissing.call(depth0, "link-to", "documents.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n                    <li>\n                        <i class=\"ace-icon glyphicon glyphicon-check blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(29, program29, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.form-report", options) : helperMissing.call(depth0, "link-to", "person.form-report", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n\n                    <li>\n                        <i class=\"ace-icon glyphicon glyphicon-check blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(31, program31, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.search-form", options) : helperMissing.call(depth0, "link-to", "person.search-form", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n                    <li>\n                        <i class=\"ace-icon fa fa-check-square blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(33, program33, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.search-parameter", options) : helperMissing.call(depth0, "link-to", "person.search-parameter", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n                    ");
      stack1 = helpers.unless.call(depth0, "isVolunteer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(35, program35, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </ul>\n                </div>\n                ");
      stack1 = helpers.unless.call(depth0, "isVolunteer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(46, program46, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n\n            <div class=\"col-md-9 col-sm-6\">\n                <div class=\"row\">\n                    <div class=\"col-md-12\">\n\n                    </div>\n                </div>\n                ");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n        </div>\n\n    </div>\n</div>\n");
      return buffer;
      }
    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                 (");
      stack1 = helpers._triageMustache.call(depth0, "timeInCurrentUnit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(")\n                    ");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                ");
      stack1 = helpers['if'].call(depth0, "unit_imported", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                ");
      return buffer;
      }
    function program8(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <div class=\"row\">\n                        <div class=\"col-sm-12 col-md-12 col-xs-12\">\n                            <div class=\"alert alert-warning center\">\n                                <i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n                                Το άτομο έχει παραπεμφθεί απο την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "unit_imported.unit_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" και περιμένει να γίνει ανάθεση\n                                <br/>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-sm btn-warning")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.accept-reference", options) : helperMissing.call(depth0, "link-to", "person.accept-reference", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </div>\n                        </div>\n                    </div>\n              ");
      return buffer;
      }
    function program9(depth0,data) {
      
      
      data.buffer.push("\n                                    Ανάθεση στην υπηρεσία\n                                ");
      }

    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"widget-box transparent collapsed\">\n                    <div class=\"widget-header widget-header-small\">\n                        <h4 class=\"widget-title blue smaller\">\n                            <i class=\"ace-icon fa fa-users\"></i>\n                            Σχέσεις\n                        </h4>\n                        <div class=\"widget-toolbar\">\n                            <a href=\"#\" data-action=\"collapse\">\n                                <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                            </a>\n                        </div>\n                    </div>\n\n                    <div class=\"widget-body\">\n                        ");
      stack1 = helpers.each.call(depth0, "related", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </div>\n                </div>\n              ");
      return buffer;
      }
    function program12(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                        <div class=\"profile-activity clearfix\">\n                            <div class=\"profile-user-info profile-user-info-striped\">\n                                <div class=\"profile-info-row\">\n                                    <div class=\"profile-info-name\">Ωφελούμενος:</div>\n                                    <div class=\"profile-info-value\">\n                                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "reference.id", options) : helperMissing.call(depth0, "link-to", "person", "reference.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    </div>\n                                </div>\n\n                                <div class=\"profile-info-row\">\n                                    <div class=\"profile-info-name\">Σχέση</div>\n                                    <div class=\"profile-info-value\">\n                                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "relationship.edit", "id", options) : helperMissing.call(depth0, "link-to", "relationship.edit", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        ");
      return buffer;
      }
    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "reference.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "type.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      return buffer;
      }

    function program17(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <li>\n                    <i class=\"ace-icon fa  fa-folder-o blue\"></i>\n                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(18, program18, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.index", options) : helperMissing.call(depth0, "link-to", "person.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </li>\n\n                <li>\n                    <i class=\"ace-icon fa fa-book blue\"></i>\n                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(20, program20, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.person-history", options) : helperMissing.call(depth0, "link-to", "person.person-history", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </li>\n\n\n                        ");
      stack1 = helpers['if'].call(depth0, "canEditData", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(22, program22, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                    ");
      return buffer;
      }
    function program18(depth0,data) {
      
      
      data.buffer.push("Στοιχεία Φακέλου");
      }

    function program20(depth0,data) {
      
      
      data.buffer.push("Ιστορικό");
      }

    function program22(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                        <li>\n                        <i class=\"ace-icon glyphicon glyphicon-pencil blue\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(23, program23, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person.edit", "", options) : helperMissing.call(depth0, "link-to", "person.edit", "", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n                    ");
      return buffer;
      }
    function program23(depth0,data) {
      
      
      data.buffer.push("Επεξεργασία Δημογραφικών");
      }

    function program25(depth0,data) {
      
      
      data.buffer.push("Δημιουργία Σχέσης");
      }

    function program27(depth0,data) {
      
      
      data.buffer.push("Καταχώρηση Εγγράφου");
      }

    function program29(depth0,data) {
      
      
      data.buffer.push("Αναφορά Φορμών");
      }

    function program31(depth0,data) {
      
      
      data.buffer.push("Επιλογή Φόρμας");
      }

    function program33(depth0,data) {
      
      
      data.buffer.push("Επιλογή Παραμέτρων");
      }

    function program35(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <li>\n                        <i class=\"ace-icon fa fa-files-o blue\"></i>\n                         ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(36, program36, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "physfiles", options) : helperMissing.call(depth0, "link-to", "physfiles", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n\n                    ");
      stack1 = helpers['if'].call(depth0, "isAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(38, program38, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      return buffer;
      }
    function program36(depth0,data) {
      
      
      data.buffer.push("Διαχ. Φυσικού Φακέλου");
      }

    function program38(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <li class=\"text-danger\">\n                        <i class=\"ace-icon fa fa-lock red\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(39, program39, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.lock", options) : helperMissing.call(depth0, "link-to", "person.lock", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n\n                    ");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(41, program41, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                    <li class=\"text-danger\">\n                        <i class=\"ace-icon fa fa-magic red\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(44, program44, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.simple-merge", options) : helperMissing.call(depth0, "link-to", "person.simple-merge", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n                    ");
      return buffer;
      }
    function program39(depth0,data) {
      
      
      data.buffer.push("<span class=\"text-danger\">Κλείδωμα φακέλου</span>");
      }

    function program41(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <li class=\"text-danger\">\n                        <i class=\"ace-icon fa fa-gears red\"></i>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(42, program42, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "pehi-admin", options) : helperMissing.call(depth0, "link-to", "pehi-admin", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </li>\n                    ");
      return buffer;
      }
    function program42(depth0,data) {
      
      
      data.buffer.push("<span class=\"text-danger\">Διαχείριση Ιστορικού</span>");
      }

    function program44(depth0,data) {
      
      
      data.buffer.push("<span class=\"text-danger\">Συγχώνευση</span>");
      }

    function program46(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"space-20\"></div>\n\n             <div>\n                <div class=\"col-md-12 col-xs-12 label label-info label-xlg \">\n                    <b>Ενέργειες Μέλους</b>\n                </div>\n              </div>\n\n              <div class=\"space-30\"></div>\n\n              <div>\n                   <button class=\"btn btn-success col-sm-12\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "dayServiceAssignment", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ημερήσια Παροχή υπηρεσίας</button>\n              </div>\n\n              <div class=\"space-30\"></div>\n\n              <div>\n                <ul class=\"list-unstyled spaced\" id=\"memberDetailsActions\">\n\n                    ");
      stack1 = helpers['if'].call(depth0, "hasCurrentUnit", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(74, program74, data),fn:self.program(47, program47, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </ul>\n              </div>\n\n              <div class=\"space-20\"></div>\n\n              <div>\n                <div class=\"widget-box transparent\" id=\"person-history-box\">\n                  <div class=\"widget-header widget-header-small\">\n                        <h4 class=\"widget-title blue smaller\">\n                            <i class=\"ace-icon fa fa-book\"></i>\n                            Ιστορικό\n                        </h4>\n                        <div class=\"widget-toolbar\">\n                            <a href=\"#\" data-action=\"collapse\">\n                                <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                            </a>\n                        </div>\n                  </div>\n                  <div class=\"widget-body\">\n                            <div class=\"widget-main padding-8\" id=\"history-scrolling\">\n                                ");
      stack1 = helpers['if'].call(depth0, "canList", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(94, program94, data),fn:self.program(91, program91, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                            </div>\n                            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "history-scrolling", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                        </div>\n                    </div>\n                </div>\n                ");
      return buffer;
      }
    function program47(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n                        ");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(67, program67, data),fn:self.program(48, program48, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      return buffer;
      }
    function program48(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n                            ");
      stack1 = helpers['if'].call(depth0, "isPrison", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(49, program49, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                            ");
      stack1 = helpers['if'].call(depth0, "canComplete", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(54, program54, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                            ");
      stack1 = helpers['if'].call(depth0, "canGraduate", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(57, program57, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                            <li>\n                                <i class=\"ace-icon fa fa-external-link blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(60, program60, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.unit-reference", options) : helperMissing.call(depth0, "link-to", "person.unit-reference", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n\n                            <li>\n                                <i class=\"ace-icon glyphicon glyphicon-remove blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(62, program62, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.withdraw", options) : helperMissing.call(depth0, "link-to", "person.withdraw", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n\n                                                                        ");
      stack1 = helpers['if'].call(depth0, "canClose", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(64, program64, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        ");
      return buffer;
      }
    function program49(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <li>\n                                <i class=\"ace-icon fa fa-taxi blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(50, program50, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.transport", options) : helperMissing.call(depth0, "link-to", "person.transport", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n\n                            <li>\n                                <i class=\"ace-icon fa fa-sign-out blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(52, program52, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.release", options) : helperMissing.call(depth0, "link-to", "person.release", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n                            ");
      return buffer;
      }
    function program50(depth0,data) {
      
      
      data.buffer.push("Μεταγωγή");
      }

    function program52(depth0,data) {
      
      
      data.buffer.push("Αποφυλάκιση");
      }

    function program54(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <li>\n                                <i class=\"ace-icon glyphicon glyphicon-ok blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(55, program55, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.accomplishment", options) : helperMissing.call(depth0, "link-to", "person.accomplishment", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n                            ");
      return buffer;
      }
    function program55(depth0,data) {
      
      
      data.buffer.push("Ολοκλήρωση");
      }

    function program57(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <li>\n                                <i class=\"ace-icon glyphicon glyphicon-check blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(58, program58, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.graduation", options) : helperMissing.call(depth0, "link-to", "person.graduation", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n                            ");
      return buffer;
      }
    function program58(depth0,data) {
      
      
      data.buffer.push("Αποφοίτηση");
      }

    function program60(depth0,data) {
      
      
      data.buffer.push("Παραπομπή");
      }

    function program62(depth0,data) {
      
      
      data.buffer.push("Αποχώρηση");
      }

    function program64(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                                                        <li>\n                                                                            <i class=\"ace-icon fa fa-arrow-circle-right\"></i>\n                                                                            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(65, program65, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.closure", options) : helperMissing.call(depth0, "link-to", "person.closure", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                                        </li>\n                                                                        ");
      return buffer;
      }
    function program65(depth0,data) {
      
      
      data.buffer.push("Κλείσιμο");
      }

    function program67(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                            ");
      stack1 = helpers['if'].call(depth0, "hasService", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(71, program71, data),fn:self.program(68, program68, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n                        ");
      return buffer;
      }
    function program68(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                    <li>\n                                    <i class=\"ace-icon fa fa-caret-right blue\"></i>\n                                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(69, program69, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.revoke-service", options) : helperMissing.call(depth0, "link-to", "person.revoke-service", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </li>\n                                ");
      return buffer;
      }
    function program69(depth0,data) {
      
      
      data.buffer.push("Διακοπή παροχής υπηρεσίας");
      }

    function program71(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                    <li>\n                                    <i class=\"ace-icon fa fa-caret-right blue\"></i>\n                                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(72, program72, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.assign-service", options) : helperMissing.call(depth0, "link-to", "person.assign-service", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </li>\n                            ");
      return buffer;
      }
    function program72(depth0,data) {
      
      
      data.buffer.push("Δήλωση παροχής υπηρεσίας");
      }

    function program74(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n\n                        ");
      stack1 = helpers['if'].call(depth0, "complex", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(86, program86, data),fn:self.program(75, program75, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      return buffer;
      }
    function program75(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                            ");
      stack1 = helpers['if'].call(depth0, "canAssign", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(76, program76, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            ");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(81, program81, data),fn:self.program(79, program79, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        ");
      return buffer;
      }
    function program76(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <li>\n                            <i class=\"ace-icon fa fa-caret-right blue\"></i>\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(77, program77, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.unit-assignment", options) : helperMissing.call(depth0, "link-to", "person.unit-assignment", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </li>\n                            ");
      return buffer;
      }
    function program77(depth0,data) {
      
      
      data.buffer.push("Ανάθεση σε υπηρεσία");
      }

    function program79(depth0,data) {
      
      
      data.buffer.push("\n                                    ");
      }

    function program81(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n                                        ");
      stack1 = helpers['if'].call(depth0, "hasService", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(84, program84, data),fn:self.program(82, program82, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    ");
      return buffer;
      }
    function program82(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                            <li>\n                                            <i class=\"ace-icon fa fa-caret-right blue\"></i>\n                                            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(69, program69, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.revoke-service", options) : helperMissing.call(depth0, "link-to", "person.revoke-service", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                        </li>\n                                        ");
      return buffer;
      }

    function program84(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                            <li>\n                                            <i class=\"ace-icon fa fa-caret-right blue\"></i>\n                                            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(72, program72, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.assign-service", options) : helperMissing.call(depth0, "link-to", "person.assign-service", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                        </li>\n                                    ");
      return buffer;
      }

    function program86(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                            ");
      stack1 = helpers['if'].call(depth0, "unit_imported", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(89, program89, data),fn:self.program(87, program87, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        ");
      return buffer;
      }
    function program87(depth0,data) {
      
      
      data.buffer.push("\n                                ");
      }

    function program89(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                    ");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(81, program81, data),fn:self.program(79, program79, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    <div class=\"row\">\n\n                                    <div class=\"col-sm-12 col-md-12 col-xs-12\">\n                                        <div class=\"alert alert-warning center\">\n                                            <i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n                                            Το άτομο βρίσκεται υπό παραπομπή\n                                        </div>\n                                    </div>\n                                </div>\n                                ");
      return buffer;
      }

    function program91(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                ");
      stack1 = helpers.each.call(depth0, "model.person_histories", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(92, program92, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                ");
      return buffer;
      }
    function program92(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                <div class=\"profile-activity clearfix\">\n                                    <div class=\"time\">\n                                        <i class=\"ace-icon fa fa-calendar bigger-110\"></i>\n                                            ");
      data.buffer.push(escapeExpression((helper = helpers['date-range'] || (depth0 && depth0['date-range']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data},helper ? helper.call(depth0, "startDate", "endDate", options) : helperMissing.call(depth0, "date-range", "startDate", "endDate", options))));
      data.buffer.push("\n                                            <a href=\"#\" class=\"red\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "deletePeHi", "", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n                                                <i class=\"ace-icon fa fa-trash-o bigger-130 pull-right\"></i>\n                                            </a>\n                                            <div class=\"space-10\"></div>\n                                  </div>\n                                  <div class=\"profile-user-info profile-user-info-striped\">\n                                      <div class=\"profile-info-row\">\n\n                                                    <div class=\"profile-info-name\">Ιδιότητα</div>\n                                                    <div class=\"profile-info-value\">\n                                                        ");
      stack1 = helpers._triageMustache.call(depth0, "status", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                    </div>\n                                            </div>\n\n\n                                        <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Δομή</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "program_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n\n                                            <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Μονάδα</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "structure_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n\n                                            <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Υπηρεσία</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "unit_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n                                        </div>\n\n                                </div>\n                              ");
      return buffer;
      }

    function program94(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                    ");
      stack1 = helpers.each.call(depth0, "model.person_histories", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(95, program95, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                ");
      return buffer;
      }
    function program95(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                <div class=\"profile-activity clearfix\">\n                                    <div class=\"time\">\n                                        <i class=\"ace-icon fa fa-calendar bigger-110\"></i>\n                                            ");
      data.buffer.push(escapeExpression((helper = helpers['date-range'] || (depth0 && depth0['date-range']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data},helper ? helper.call(depth0, "startDate", "endDate", options) : helperMissing.call(depth0, "date-range", "startDate", "endDate", options))));
      data.buffer.push("\n\n                                            <div class=\"space-10\"></div>\n                                  </div>\n                                  <div class=\"profile-user-info profile-user-info-striped\">\n                                      <div class=\"profile-info-row\">\n\n                                                    <div class=\"profile-info-name\">Ιδιότητα</div>\n                                                    <div class=\"profile-info-value\">\n                                                        ");
      stack1 = helpers._triageMustache.call(depth0, "status", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                    </div>\n                                            </div>\n\n\n                                        <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Δομή</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "program_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n\n                                            <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Μονάδα</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "structure_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n\n                                            <div class=\"profile-info-row\">\n\n                                                <div class=\"profile-info-name\">Υπηρεσία</div>\n                                                <div class=\"profile-info-value\">\n                                                    ");
      stack1 = helpers._triageMustache.call(depth0, "unit_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                                </div>\n                                            </div>\n                                        </div>\n\n                                </div>\n                              ");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "isLocked", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });