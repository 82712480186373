define("front/controllers/form-history", 
  ["ember-notify","front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];
    var UserMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(UserMixin, {
      needs: ["application"],
      formHistory: [],
      formName: null,
      personName: null,
      firstForm: null,
      header: null,
      data: null
    });
  });