define("front/controllers/person/form-report", 
  ["front/config/environment","front/mixins/person","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];
    var UserMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, UserMixin, Ember.Validations.Mixin, {
      needs: ["application"],

      canValidate: false,

      validations: {},

      actions: {
        printReport: function () {
          var self = this;
          this.set("canValidate", true);

          var forms = this.get("selectedForms").map(function (f) {
            return f.id;
          }).join(",");

          this.validate().then(function () {
            self.set("canValidate", false);
            self.send("showModal", "print-modals/print-wait");

            $.fileDownload(ENV.APP.backendUrl + "/reports/form-report/" + self.get("person.id"), {
              httpMethod: "POST",
              data: {
                Authorization: "Bearer " + self.get("session.access_token"),
                date_from: self.get("dateFrom"),
                date_to: self.get("dateTo"),
                forms: forms }
            }).done(function () {
              $(".modal").modal("hide");
            }).fail(function () {
              $(".modal").modal("hide");
            });
          }, function () {
            self.set("canValidate", false);
          });
        }
      }

    });
  });