define("front/serializers/person", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        currentUnit: { embedded: "always" },
        physfiles: { embedded: "always" },
        nationality: { embedded: "always" },
        type: { embedded: "always" }
      }
    });
  });