define("front/controllers/person/accept-reference", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      needs: ["application"],
      person: null,
      user_id: null,
      transfers: null,
      user_current_unit: null,
      physfile_code: null,

      checkPhys: (function () {
        var searchText = this.get("prefix") + "-";
        console.log(searchText);
        var regExPattern = searchText + "\\b";
        var regexp = new RegExp(regExPattern, "gi");

        return this.get("person.physfiles").filter(function (physfile) {
          return physfile.get("displaycode").match(regexp);
        });
      }).property("person.physfiles"),

      actions: {
        accept: function () {
          var data = Ember.Object.create({
            user_id: this.get("user_id"),
            pers_id: this.get("model.person.id"),
            date: this.get("date"),
            physfile_code: this.get("checkPhys.length") ? null : this.get("physfile_code"),
            physfile_displaycode: this.get("checkPhys.length") ? null : this.get("user_current_unit.prefix") + "-" + this.get("physfile_code"),
            pevn_id: this.get("transfer.pevn_id"),
            unit_id: this.get("user_current_unit.id")
          });

          var self = this;


          $.ajax({
            url: ENV.APP.backendUrl + "/acceptReference",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Το άτομο ανατέθηκε στην υπηρεσία " + self.get("user_current_unit.name") + "με επιτυχία", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            var notification = self.get("controllers.application.notifications").filterBy("pevn_id", self.get("transfer.pevn_id")).get("firstObject");
            self.set("controllers.application.notifications_unseen_cnt", self.get("controllers.application.notifications_unseen_cnt") - 1);
            if (notification) {
              self.get("controllers.application.notifications").removeObject(notification);
            }
            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("model.person.id"));
          }, function (response) {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            Notify.warning(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });