define("front/routes/person-forms", 
  ["simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Ember = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        //var formModel = this.modelFor('form');
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
        this.store.unloadAll("personForm");

        var self = this;

        var selectedUnit = this.controllerFor("application").get("selectedUnit");

        var personFormPromise = this.store.find("personForm", params.person_form_id);

        return Ember.RSVP.all([personFormPromise]).then(function (personForm) {
          //console.log(personForm[0].get('form.hasGroups'));
          if (personForm[0].get("form.hasGroups")) {
            return Ember.RSVP.hash({
              personForm: personForm[0],
              form: self.store.find("form", personForm[0].get("form.id")),
              groups: self.store.find("formGroup", {
                form_id: personForm[0].get("form.id"),
                person_form_id: personForm[0].get("id"),
                results: "true"
              }),
              parameters: null,
              person: self.store.find("person", personForm[0].get("person.id")),
              users: self.store.find("user", { program_unit_id: selectedUnit.id })
            });
          } else {
            return Ember.RSVP.hash({
              personForm: personForm[0],
              form: self.store.find("form", personForm[0].get("form.id")),
              groups: null,
              parameters: self.store.find("formParameter", {
                form_id: personForm[0].get("form.id"),
                person_form_id: personForm[0].get("id"),
                results: "true"
              }),
              person: self.store.find("person", personForm[0].get("person.id")),
              users: self.store.find("user", { program_unit_id: selectedUnit.id })
            });
          }
        });

      },

      setupController: function (controller, model) {
        var selectedUser = model.users.findBy("id", model.personForm.get("userId"));

        this.controllerFor("form-complete").set("personForm", model.personForm);
        this.controllerFor("form-complete").set("person", model.person);
        this.controllerFor("form-complete").set("users", model.users);
        this.controllerFor("form-complete").set("selectedFormUser", selectedUser);
        this.controllerFor("form-complete").set("form", model.form);
        this.controllerFor("form-complete").set("groups", model.groups);
        this.controllerFor("form-complete").set("parameters", model.parameters);
        this.controllerFor("form-complete").set("onlyParameter", false);
        this.controllerFor("form-complete").set("editForm", true);

        this.controllerFor("form-complete").set("formDate", model.personForm.get("date"));
      },

      renderTemplate: function () {
        this.render("form-complete", {
          controller: "form-complete"
        });
      },

      deactivate: function () {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
      }


    });
  });