define("front/controllers/person/unit-assignment", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    __exports__["default"] = Ember.Controller.extend({

      person: null,
      user_id: null,
      user_current_unit: null,
      crisis: false,
      isAddict: false,
      isFamily: false,
      isOther: false,
      hasPhysfile: true,
      physfile_code: null,

      checkPhys: (function () {
        var searchText = this.get("prefix") + "-";
        var regExPattern = searchText + "\\b";
        var regexp = new RegExp(regExPattern, "gi");
        return this.get("person.physfiles").filter(function (physfile) {
          return physfile.get("displaycode").match(regexp);
        });
      }).property("person.physfiles"),

      actions: {
        assign: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);

          var data = Ember.Object.create({
            user_id: this.get("user_id"),
            pers_id: this.get("model.person.id"),
            date: this.get("date"),
            crisis: 0,
            physfile_code: this.get("checkPhys.length") ? null : this.get("physfile_code"),
            physfile_displaycode: this.get("checkPhys.length") ? null : this.get("user_current_unit.prefix") + "-" + this.get("physfile_code"),
            isFamily: 1,
            isAddict: null,
            isOther: null,
            unit_id: this.get("user_current_unit.id")
          });

          var self = this;
          console.log(this.get("physfile_code"));

          $.ajax({
            url: ENV.APP.backendUrl + "/unitAssignment",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Το άτομο αντέθηκε στην υπηρεσία " + self.get("user_current_unit.name") + "με επιτυχία", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
            self.set("canValidate", false);
            self.set("showSpinner", false);
            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("model.person.id"));
          }, function (response) {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });