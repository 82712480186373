define("front/templates/administration", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n					<a class=\"dropdown-toggle\" href=\"#\">\n						<i class=\"menu-icon fa fa-pencil-square-o\"></i>\n						<span class=\"menu-text\"> Φόρμες </span>\n						<b class=\"arrow\"></b>\n					</a>\n\n\n					<ul class=\"submenu\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hsub"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "forms.new", options) : helperMissing.call(depth0, "link-to", "forms.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</ul>\n				");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'bubbles': (false)
      },hashTypes:{'bubbles': "BOOLEAN"},hashContexts:{'bubbles': depth0},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "forms.new", options) : helperMissing.call(depth0, "link-to", "forms.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program3(depth0,data) {
      
      
      data.buffer.push("\n								<span class=\"menu-text\"> Νέα φόρμα </span>\n								<b class=\"arrow\"></b>\n							");
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n					<a class=\"dropdown-toggle\" href=\"#\">\n						<i class=\"menu-icon fa fa-pencil-square-o\"></i>\n						<span class=\"menu-text\"> Παράμετροι </span>\n						<b class=\"arrow\"></b>\n					</a>\n\n\n					<ul class=\"submenu\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hsub"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "parameters.new", options) : helperMissing.call(depth0, "link-to", "parameters.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</ul>\n				");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'bubbles': (false)
      },hashTypes:{'bubbles': "BOOLEAN"},hashContexts:{'bubbles': depth0},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "parameters.new", options) : helperMissing.call(depth0, "link-to", "parameters.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program7(depth0,data) {
      
      
      data.buffer.push("\n								<span class=\"menu-text\"> Νέα παράμετρος </span>\n								<b class=\"arrow\"></b>\n							");
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n					<a class=\"dropdown-toggle\" href=\"#\">\n						<i class=\"menu-icon fa fa-plus-square\"></i>\n						<span class=\"menu-text\"> Κέντρα </span>\n						<b class=\"arrow\"></b>\n					</a>\n\n\n					<ul class=\"submenu\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hsub"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "programs", options) : helperMissing.call(depth0, "link-to", "programs", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</ul>\n				");
      return buffer;
      }
    function program10(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'bubbles': (false)
      },hashTypes:{'bubbles': "BOOLEAN"},hashContexts:{'bubbles': depth0},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "programs", options) : helperMissing.call(depth0, "link-to", "programs", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program11(depth0,data) {
      
      
      data.buffer.push("\n								<span class=\"menu-text\"> Νέα Δομή </span>\n								<b class=\"arrow\"></b>\n							");
      }

    function program13(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n					<a class=\"dropdown-toggle\" href=\"#\">\n						<i class=\"menu-icon fa fa-sitemap\"></i>\n						<span class=\"menu-text\"> Υπηρεσίες </span>\n						<b class=\"arrow\"></b>\n					</a>\n\n\n					<ul class=\"submenu\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hsub"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(14, program14, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "units.new", options) : helperMissing.call(depth0, "link-to", "units.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</ul>\n				");
      return buffer;
      }
    function program14(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'bubbles': (false)
      },hashTypes:{'bubbles': "BOOLEAN"},hashContexts:{'bubbles': depth0},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "units.new", options) : helperMissing.call(depth0, "link-to", "units.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program15(depth0,data) {
      
      
      data.buffer.push("\n								<span class=\"menu-text\"> Προσθήκη Υπηρεσίας </span>\n								<b class=\"arrow\"></b>\n							");
      }

    function program17(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n					<a class=\"dropdown-toggle\" href=\"#\">\n						<i class=\"menu-icon fa fa-user\"></i>\n						<span class=\"menu-text\"> Χρήστες </span>\n						<b class=\"arrow\"></b>\n					</a>\n\n\n					<ul class=\"submenu\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hsub"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(18, program18, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "users.new", options) : helperMissing.call(depth0, "link-to", "users.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</ul>\n				");
      return buffer;
      }
    function program18(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'bubbles': (false)
      },hashTypes:{'bubbles': "BOOLEAN"},hashContexts:{'bubbles': depth0},inverse:self.noop,fn:self.program(19, program19, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "users.new", options) : helperMissing.call(depth0, "link-to", "users.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program19(depth0,data) {
      
      
      data.buffer.push("\n								<span class=\"menu-text\"> Νέος Χρήστης </span>\n								<b class=\"arrow\"></b>\n							");
      }

      data.buffer.push("<div class=\"row\">\n	<div class=\"col-sm-12\">\n		<div id=\"admin-sidebar\" class=\"sidebar h-sidebar\">\n\n			<ul class=\"nav nav-list\">\n\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hover"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "forms", options) : helperMissing.call(depth0, "link-to", "forms", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hover"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "parameters", options) : helperMissing.call(depth0, "link-to", "parameters", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hover"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "programs", options) : helperMissing.call(depth0, "link-to", "programs", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hover"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "units", options) : helperMissing.call(depth0, "link-to", "units", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("hover"),
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'class': "STRING",'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'class': depth0,'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(17, program17, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "users", options) : helperMissing.call(depth0, "link-to", "users", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</ul>\n		</div>\n	</div>\n</div>\n<div class=\"space-20\"></div>\n\n<div class=\"row\">\n	");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n</div>\n\n");
      return buffer;
      
    });
  });