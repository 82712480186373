define("front/controllers/person", 
  ["front/config/environment","ember-notify","front/mixins/person","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];
    var UserMixin = __dependency4__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, UserMixin, {
      needs: ["application"],
      person_histories: null,
      person: null,
      user_id: null,
      user_current_unit: null,
      transfers: null,
      personCrntUn: null,
      locked: null,
      isAdmin: false,
      related: [],

      canList: (function () {
        return this.get("isAdmin");
      }).property("isAdmin"),

      timeInCurrentUnit: (function () {
        var ph = this.get("person_histories").filterBy("endDate", null).filterBy("type", "1").get("firstObject");
        if (ph) {
          var diff = moment.preciseDiff(ph.get("startDate"), moment());
          return diff;
        }
        return null;
      }).property("person_histories"),

      displayRelationships: (function () {
        var rels = this.get("model.relationships");
        var pers = this.get("model.person.id");
        var related = this.get("related");

        rels.forEach(function (item) {
          var pers1 = item.get("pers1.id");
          var pers2 = item.get("pers2.id");

          if (pers === pers1) {
            related.pushObject({
              id: item.get("id"),
              reference: item.get("pers2"),
              type: item.get("relationtype2")
            });
          }

          if (pers === pers2) {
            related.pushObject({
              id: item.get("id"),
              reference: item.get("pers1"),
              type: item.get("relationtype1")
            });
          }
        });

      }).observes("model.relationships"),

      actions: {
        dayServiceAssignment: function () {
          var self = this;

          var data = Ember.Object.create({
            user_id: this.get("user_id"),
            pers_id: this.get("person.id"),
            date: moment().format("DD/MM/YYYY"),
            unit_id: this.get("user_current_unit.id")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/DayServiceAssignment",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Το άτομο δέχεται υπηρεσίες απο την υπηρεσία ", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("person.id"));
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        unLockMember: function () {
          var self = this;

          // Ajax Data Object
          var data = Ember.Object.create({
            pers_id: this.get("person.id"),
            user_id: this.get("user_id"),
            lock: 0
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/lockMember",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Ο φάκελος του χρήστη ξεκλειδώθηκε", {
              closeAfter: 10000
            });
            self.send("reloadPerson");
          }, function (error) {
            Notify.warning("Το ξεκλείδωμα του φακέλου απέτυχε.", {
              closeAfter: 10000
            });
          });
        },

        deletePeHi: function (pehi) {
          this.send("showModal", "person/delete-pehi", { pehi: pehi });
        },

        printVevaiwsi: function () {
          var self = this;
          var person_id = this.get("person.id");

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/print-vevaiwsi", {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              person_id: person_id,
              current_unit_id: self.get("controllers.application.selectedUnit.id")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });