define("front/templates/form-history", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n<div class=\"col-xs-12\" id=\"form_div\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			<h3 class=\"widget-title smaller\">Ιστορικό Φόρμας ");
      stack1 = helpers._triageMustache.call(depth0, "formName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(", ");
      stack1 = helpers._triageMustache.call(depth0, "personName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main hov\">\n                <table class=\"table table-bordered table-hover fht\">\n                    <thead>\n                        <tr>\n                            <th>Ερωτήσεις</th>\n                            ");
      stack1 = helpers.each.call(depth0, "h", "in", "header", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </tr>\n                    </thead>\n                    <tbody>\n                      \n                        ");
      stack1 = helpers.each.call(depth0, "row", "in", "data", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        \n                    </tbody>\n                </table>\n                \n\n            </div>\n        </div>\n    </div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                <th>");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person-forms", "h.formId", options) : helperMissing.call(depth0, "link-to", "person-forms", "h.formId", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</th>\n                            ");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', stack1;
      stack1 = helpers._triageMustache.call(depth0, "h.dateString", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<br><small>");
      stack1 = helpers._triageMustache.call(depth0, "h.formUser", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                        <tr>\n                           ");
      stack1 = helpers.each.call(depth0, "el", "in", "row", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" \n                        </tr>\n                        ");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n                           <td>");
      data.buffer.push(escapeExpression((helper = helpers['new-line'] || (depth0 && depth0['new-line']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "el", options) : helperMissing.call(depth0, "new-line", "el", options))));
      data.buffer.push("</td>\n                           ");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "firstForm", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });