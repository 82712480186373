define("front/views/users/user-units-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",

      viewRerender: (function () {
        this.rerender();
      }).observes("controller.units"),

      didInsertElement: function () {
        var self = this;

        var data;
        if (this.get("controller.units")) {
          data = this.get("controller.units").getEach("_data");
        }

        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Δομή", mData: "programName" }, { sTitle: "Υπηρεσία", mData: "name" }],
          aaSorting: [[0, "asc"], [1, "asc"]] });
      }
    });
  });