define("front/controllers/person/index", 
  ["front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.ArrayController.extend(UserMixin, {

      needs: ["application"],

      documentsChecked: true,
      eventsChecked: true,
      formsChecked: true,
      groupsChecked: false,
      paramsChecked: true,
      presencesChecked: false,
      selectedCategory: null,
      selectedSubCategory: null,
      subcategories: null,
      globalSearch: null,
      categories: null,
      person: null,
      pers_prog_id: null,
      user_prog_id: null,
      isAdmin: false,

      sameProgram: (function () {
        if (this.get("person_histories").filterBy("program_id", this.get("user_prog_id")).get("firstObject")) {
          return true;
        }
        return false;
      }).property("person_histories", "user_prog_id"),

      onSelectCat: (function () {
        this.set("selectedSubCategory", "");
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);
        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
          this.set("selectedSubCategory", "");
        }
        this.send("getElementHistory");
      }).observes("selectedCategory"),

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);
        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("selectedSubCategory", "");
        }

        this.send("getElementHistory");
      }).observes("selectedSubCategory"),

      triggergetElementHistory: (function () {
        this.send("getElementHistory");
      }).observes("documentsChecked", "eventsChecked", "formsChecked", "groupsChecked", "paramsChecked", "presencesChecked"),

      actions: {

        favouriteEvent: function (event_id, fclass, newClass) {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/favouriteEvent/" + event_id,
            type: "POST" }).then(function (response) {
            $("#favouriteEvent" + event_id + " i").removeClass(fclass).addClass(newClass);
            $("#favouriteEvent" + event_id).attr("data-class", newClass);
            self.send("getElementHistory");
          });
        },

        favouriteForm: function (event_id, fclass, newClass) {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/favouriteForm/" + event_id,
            type: "POST" }).then(function (response) {
            $("#favouriteForm" + event_id + " i").removeClass(fclass).addClass(newClass);
            $("#favouriteForm" + event_id).attr("data-class", newClass);
            self.send("getElementHistory");
          });
        },

        favouriteDoc: function (event_id, fclass, newClass) {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/favouriteDoc/" + event_id,
            type: "POST" }).then(function (response) {
            $("#favouriteDoc" + event_id + " i").removeClass(fclass).addClass(newClass);
            $("#favouriteDoc" + event_id).attr("data-class", newClass);
            self.send("getElementHistory");
          });
        },

        downloadFile: function (pers_id, docname) {
          $.fileDownload(ENV.APP.backendUrl + "/personFile?pers_id=" + pers_id + "&doc_name=" + docname, {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token") }
          });
        },

        showEvent: function (pers_id, event_id) {
          this.transitionTo("event", pers_id, event_id);
        },

        getElementHistory: function () {
          this.set("model", null);
          var selectedCategoryId = this.get("selectedCategory.id");
          var selectedSubCategoryId = this.get("selectedSubCategory.id");
          this.set("showSpinner", true);
          var self = this;
          this.store.find("element_history", {
            pers_id: self.get("person.id"),
            searchString: self.get("searchString"),
            category_id: selectedCategoryId,
            subcategory_id: selectedSubCategoryId,
            documents: this.get("documentsChecked").toString(),
            events: this.get("eventsChecked").toString(),
            forms: this.get("formsChecked").toString(),
            groups: this.get("groupsChecked").toString(),
            params: this.get("paramsChecked").toString(),
            presences: this.get("presencesChecked").toString() }).then(function (data) {
            self.set("showSpinner", false);
            self.set("model", data);
          });
        },

        showDeleteModal: function (id, type, name) {
          this.send("showModal", "person/delete-element", { id: id, type: type, name: name });
        },

        printElements: function () {
          var data;
          var self = this;

          if (this.get("model")) {
            data = this.get("model").getEach("data");
          }


          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/print-stoixeia-fakelou", {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              data: JSON.stringify(data),
              pers_id: self.get("person.id"),
              current_unit_id: self.get("controllers.application.selectedUnit.id")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });