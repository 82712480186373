define("front/controllers/ithaki-helplines/edit", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      canValidate: false,

      gender_enum: [{ id: 1, value: "1.Άνδρας" }, { id: 2, value: "2.Γυναίκα" }, { id: 9, value: "9.Άγνωστο" }],

      relation_enum: [{ id: 1, value: "1.Χρήστης" }, { id: 2, value: "2.Σύντροφος" }, { id: 3, value: "3.Γονιός" }, { id: 4, value: "4.Αδελφός/η" }, { id: 5, value: "5.Παππούς/γιαγιά" }, { id: 6, value: "6.Θείος/α" }, { id: 7, value: "7.Ξάδελφος/η" }, { id: 8, value: "8.Φίλος/η" }, { id: 9, value: "9.Επαγγελματίας" }, { id: 10, value: "10.Παιδί χρήστη" }, { id: 11, value: "11.Άλλο" }, { id: 99, value: "99. Άγνωστο" }],

      marital_status_enum: [{ id: 1, value: "1.Άγαμος/η" }, { id: 2, value: "2.Έγγαμος/η" }, { id: 3, value: "3.Διαζευγμένος/η" }, { id: 3, value: "4. Χήρος/α" }, { id: 5, value: "5.Άλλο" }, { id: 9, value: "9.Άγνωστο" }],

      job_status_enum: [{ id: 1, value: "1. Σταθερή απασχόληση" }, { id: 2, value: "2. Περιστασιακή εργασία " }, { id: 3, value: "3. Οικονομικά μη ενεργός(συνταξιούχος, οικιακά, φαντάρος)" }, { id: 4, value: "4. Άνεργος" }, { id: 5, value: "5. Φοιτητής /μαθητής" }, { id: 8, value: "8.Άλλο" }, { id: 9, value: "9.Άγνωστο" }],

      dependency_enum: [{ id: 1, value: "1. Ναρκωτικές ουσίες" }, { id: 2, value: "2. Αλκοόλ" }, { id: 3, value: "3. Τζόγος" }, { id: 4, value: "4. Διαδίκτιο" }, { id: 5, value: "5. Καμία" }, { id: 6, value: "6. Άλλο" }, { id: 9, value: "9. Άγνωστο" }],

      service_type_enum: [{ id: 1, value: "1. Πληροφορίες/ενημέρωση" }, { id: 2, value: "2. Υποστήριξη/συμβουλευτική " }, { id: 3, value: "3.Άλλο" }],

      service_transfer_enum: [{ id: 1, value: "1.ΚΕΘΕΑ" }, { id: 2, value: "2. ΟΚΑΝΑ" }, { id: 3, value: "3. Ψυχιατρικό Νοσοκομείο Θεσσαλονίκης" }, { id: 4, value: "4. Ψυχιατρικό Νοσοκομείο Αθηνών" }, { id: 5, value: "5. Δομή Ψυχικής Υγείας" }, { id: 6, value: "6. Ομάδες Ν.Α/Α.Α. " }, { id: 7, value: "7. Τηλεφωνική Γραμμή Βοήθειας" }, { id: 8, value: "8. Δομή Προαγωγής Αυτοβοήθειας" }, { id: 9, value: "9.Άλλο" }, { id: 99, value: "99.Καμία" }],

      validations: {

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        consultant_id: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Σύμβουλο." }
        },

        duration: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Διάρκεια." }
        }
      },

      actions: {
        requestBehaviour: function () {
          var hideRadio = this.get("call_request") === 0 ? false : true;
          this.set("hideRadio", hideRadio);
        },

        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var helpline = this.get("content");

          this.validate().then(function () {
            helpline.set("date", moment(self.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"));
            helpline.set("consultant_id", self.get("consultant.id"));
            helpline.save().then(function () {
              self.transitionToRoute("ithaki-helplines.index");
              self.send("reloadHelplines");
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            }, function (error) {
              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
              helpline.set("date", moment(self.get("date"), "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"));
              self.set("canValidate", false);
              self.set("showSpinner", false);
            });
          }, function (error) {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }
    });
  });