define("front/models/forminfo", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Forminfo = DS.Model.extend({
      name: attr() });

    __exports__["default"] = Forminfo;
  });